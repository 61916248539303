import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

import styled from "styled-components";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Typography from "@material-ui/core/Typography";

import mock1Image from "../assets/mock1.jpg";
import { useController, useFormContext } from "react-hook-form";
import { API_URL } from "../constants/EnvConfig";
import Loader from "./Loader";
import { useAuthContext } from "../context/AuthContext";

const Container = styled.div({
  minHeight: 250,
  paddingTop: 16,
  paddingBottom: 16,
  borderWidth: 1,
  border: "1px solid rgba(255, 255, 255, 0.23)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 4,
  position: "relative",
  overflow: "hidden",
});

const Icon = styled(CloudUploadIcon)({
  color: "rgba(255, 255, 255, 0.7)",
  position: "relative",
});

const Text = styled(Typography)({
  color: "rgba(255, 255, 255, 0.7)",
  position: "relative",
});

const Image = styled.div(({ $src }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: "100%",
  height: "100%",
  backgroundImage: `url(${$src})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",

  "&:after": {
    content: `""`,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0 ,0,0, .25)",
  },
}));

const upload = async (url, options, token) => {
  const response = await fetch(`${API_URL}${url}`, {
    ...options,
    headers: {
      Accept: "application/json",
      Authorization: token && `Bearer ${token}`,
    },
    method: "PUT",
  });
  return await response.json();
};

function Dropzone({ url, name, rules, defaultValue = "", disabled }) {
  const { control } = useFormContext();
  const {
    field: { ref: inputRef, onChange, value },
    fieldState: { error: { message } = {}, invalid: error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const [loading, setLoading] = useState();
  const { userToken: token } = useAuthContext();

  const remoteUpload = useCallback(
    async (file) => {
      const formData = new FormData();
      try {
        formData.append("image", file);
        const { key } = await upload(
          //   `/asset/${category}/${id}`
          url,
          {
            body: formData,
          },
          token
        );
        return key;
      } catch (ex) {
        console.log({ ex });
      }
    },
    [token, url]
  );

  const onDrop = useCallback(
    async (files) => {
      setLoading(true);
      const file = await remoteUpload(files[0]);
      setLoading(false);
      onChange(file);
    },
    [onChange, remoteUpload]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: "image/*",
  });

  if (loading) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  if (disabled && value) {
    return (
      value && (
        <Container>
          <Image $src={value} />
        </Container>
      )
    );
  }

  return (
    <Container {...getRootProps()}>
      <input {...getInputProps()} />
      {value && <Image $src={value} />}
      <Icon fontSize="large" />
      <Text>Drag 'n' drop some files here, or click to select files</Text>
    </Container>
  );
}

// function Dropzone({
//   items = [],
//   name,
//   rules,
//   defaultValue = "",
//   disabled,
//   children,
// }) {
//   const { control } = useFormContext();
//   const {
//     field: { ref: inputRef, onChange, value },
//     fieldState: { error: { message } = {}, invalid: error },
//   } = useController({
//     name,
//     control,
//     rules,
//     defaultValue,
//   });

//   return <DropzoneArea showPreviewsInDropzone />;
// }

export default Dropzone;
