import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

function useAdminReport() {
  const { response, loading, post } = useAuthFetch({});

  function refresh(body) {
    const url = `${API_URL}/report/admin/search`;
    return post({ url, body });
  }

  return { response, loading, refresh };
}

export default useAdminReport;
