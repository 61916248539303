import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import { FormProvider } from "react-hook-form";
import styled from "styled-components";
import Loader from "../components/Loader";
import TextField from "../components/TextField";
import { useAppContext } from "../context/AppContext";
import { REQUIRED_RULE } from "../helpers/Form";
import useMatch from "../hooks/api/useMatch";
import useForm from "../hooks/useForm";

const HomeInput = styled(TextField).attrs({
  name: "home",
  type: "tel",
  rules: REQUIRED_RULE,
})({});

const AwayInput = styled(TextField).attrs({
  name: "away",
  type: "tel",
  rules: REQUIRED_RULE,
})({});

function EndMatch({ match, open, onClose }) {
  const { setMessage } = useAppContext();

  const form = useForm();
  const {
    handleSubmit,

    // setValue, formState,
    reset,
  } = form;
  // const { isSubmitted, isSubmitSuccessful, isValid } = formState;

  const { id } = match ?? {};
  const { loading, update } = useMatch({ id });

  async function onSubmit(data) {
    const { home, away } = data;

    try {
      await update({ score: `${home} - ${away}` });
      setMessage("Match updated");
      onClose();
      reset();
    } catch (ex) {
      setMessage({ message: "Update match fail" });
    }
  }

  function renderContent() {
    const { name, home, away } = match ?? {};
    return (
      <>
        <DialogTitle>Update {name}'s Score</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <HomeInput label={home} />
            </Grid>
            <Grid item lg={6} xs={12}>
              <AwayInput label={away} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="primary">
            End Match
          </Button>
        </DialogActions>
      </>
    );
  }

  return (
    <FormProvider {...form}>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {loading ? <Loader /> : renderContent()}
        </form>
      </Dialog>
    </FormProvider>
  );
}

export default EndMatch;
