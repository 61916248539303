import React, { createContext, useContext, useState } from "react";

export const AppContext = createContext();

function AppContextProvider(props) {
  const [pageTitle, setPageTitle] = useState();

  const [message, setMessage] = useState();

  const value = {
    pageTitle,
    message,

    setMessage,
    setPageTitle,
  };
  const ctxProps = { ...props, value };
  return <AppContext.Provider {...ctxProps} />;
}

export const useAppContext = () => useContext(AppContext);

export default AppContextProvider;
