import { useForm as useLibForm } from "react-hook-form";

const useForm = (props) => {
  return useLibForm({
    mode: "onTouched",
    shouldUnregister: false,
    ...props,
  });
};

export default useForm;
