import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import LibDivider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import styled from "styled-components";
import { formatStandard } from "../helpers/DateTime";

const Row = styled.div(({ space, theme }) => ({
  display: "flex",
  flexDirection: "row",
  flex: 1,
  marginBottom: theme.spacing(space),
}));

const Col = styled.div(({ align }) => ({
  display: "flex",
  flex: 1,
  justifyContent: align === "right" ? "flex-end" : "",
}));

const ReceiptNo = styled(Typography).attrs({
  variant: "subtitle2",
})(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const Head = styled(Typography).attrs({
  variant: "overline",
  color: "textSecondary",
})({});

const Name = styled(Typography).attrs(({ main }) => ({
  variant: main ? "body1" : "subtitle2",
}))(({ error, main, theme }) => ({
  color: error ? "#FF0000" : "",
  fontWeight: error ? "bold" : "",
  //   paddingLeft: main ? 0 : theme.spacing(1),
}));

const Price = styled(Typography).attrs({
  variant: "subtitle2",
})(({ error, main, theme }) => ({
  color: error ? "#FF0000" : "",
  fontWeight: "bold",
  //   paddingLeft: main ? 0 : theme.spacing(1),
}));

const Total = styled(Typography).attrs(({ main }) => ({
  variant: "h6",
  color: main ? "" : "textSecondary",
}))(({ main, theme }) => ({
  //   paddingLeft: main ? 0 : theme.spacing(1),
}));

const Divider = styled(LibDivider)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const BottomText = styled(Typography).attrs(({ main }) => ({
  variant: "body2",
  color: "textSecondary",
}))(({ main }) => ({
  fontWeight: main && "bold",
}));

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function Receipt({ item, open, onClose }) {
  if (!item) {
    return null;
  }

  const {
    status,
    receiptNo,
    products = [],
    totalAmount,
    remark,
    paidBy,
    matchName,
    matchAt,
    createdAt,
  } = item;

  const voidTicket = status === "void";

  return (
    <Dialog fullWidth maxWidth="sm" onClose={onClose} open={open}>
      <DialogContent>
        <ReceiptNo>
          Receipt No: {receiptNo} {voidTicket && "(VOID)"}
        </ReceiptNo>
        <Row>
          <Col>
            <Head>Products</Head>
          </Col>
          <Col align="right">
            <Head>Odd</Head>
          </Col>
          <Col align="right">
            <Head>Price</Head>
          </Col>
        </Row>
        <Divider />
        {products.map((prd) => (
          <Row space={2}>
            <Col>
              <Name error={!!prd.remark}>{prd.score}</Name>
            </Col>
            <Col align="right">
              <Name error={!!prd.remark}>{prd.remark ?? prd.amount}</Name>
            </Col>
            <Col align="right">
              <Price error={!!prd.remark}>{prd.betAmount}</Price>
            </Col>
          </Row>
        ))}
        <Divider />
        <Row space={2}>
          <Col></Col>
          <Col>
            <Total>Total</Total>
          </Col>
          <Col align="right">
            <Total main>$ {totalAmount}</Total>
          </Col>
        </Row>
        <Divider />

        <Row space={1}>
          <Col></Col>
          <Col>
            <BottomText>Remark</BottomText>
          </Col>
          <Col align="right">
            <BottomText main>{remark || "-"}</BottomText>
          </Col>
        </Row>

        <Row space={1}>
          <Col></Col>
          <Col>
            <BottomText>Paid by</BottomText>
          </Col>
          <Col align="right">
            <BottomText main>{paidBy}</BottomText>
          </Col>
        </Row>
        <Row space={1}>
          <Col></Col>
          <Col>
            <BottomText>Paid at</BottomText>
          </Col>
          <Col align="right">
            <BottomText main>{formatStandard(createdAt)}</BottomText>
          </Col>
        </Row>

        <Row space={1}>
          <Col></Col>
          <Col>
            <BottomText>Match</BottomText>
          </Col>
          <Col align="right">
            <BottomText main>{matchName}</BottomText>
          </Col>
        </Row>

        <Row space={1}>
          <Col></Col>
          <Col>
            <BottomText>Match Date</BottomText>
          </Col>
          <Col align="right">
            <BottomText main>{formatStandard(matchAt)}</BottomText>
          </Col>
        </Row>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Receipt;
