const formatAmount = (amount) => {
  const amt = amount || 0;
  return +(Math.round(amt * 100) / 100).toFixed(2);
};

export const sumGroupbyReportTxns = (groupbyTxns) => {
  // convert txn { paidBy, poducts } to reportTxn { sales , comm , fight}
  return Object.values(
    groupbyTxns.reduce((prev, txn) => {
      const {
        sales = 0,
        commission = 0,
        payout = 0,
        fight = 0,
        total = 0,
        userName,
      } = txn;

      const user = prev[userName] || {
        sales: 0,
        commission: 0,
        payout: 0,
        fight: 0,
        total: 0,
        netAmount: 0,
      };

      const netAmount = sales - commission - fight;

      return {
        ...prev,
        [userName]: {
          sales: formatAmount(sales + user.sales || 0),
          commission: formatAmount(commission + user.commission || 0),
          payout: formatAmount(payout + user.payout || 0),
          fight: formatAmount(fight + user.fight || 0),
          total: formatAmount(total + user.total || 0),
          netAmount: formatAmount(netAmount + user.netAmount || 0),
          userName,
        },
      };
    }, {})
  );
};

export const getReportParams = (reportTxns, ownAgent, groupedTxns = []) => {
  const groupbyTxns =
    groupedTxns.length === 0 ? groupbyReportTxns(reportTxns) : groupedTxns;

  const {
    totalSales = 0,
    totalCommission = 0,
    totalPayout = 0,
    totalFight = 0,
    totalAmount = 0,
    totalNetAmount = 0,
  } = sumGroupbyReport(groupbyTxns);

  let perc = 100;
  groupbyTxns.forEach((txn, index) => {
    if (index === groupbyTxns.length - 1) {
      txn.perc = perc;
    } else {
      txn.perc = (txn.netAmount / totalNetAmount) * 100;
      perc -= txn.perc;
    }
  });

  groupbyTxns.sort((b, a) => +a.total - +b.total);

  const defaultProps = {
    totalSales: formatAmount(totalSales),
    totalCommission: formatAmount(totalCommission),
    totalPayout: formatAmount(totalPayout),
    totalFight: formatAmount(totalFight),
    totalAmount: formatAmount(totalAmount),
    txns: groupbyTxns,
  };

  if (!ownAgent) {
    return defaultProps;
  }

  const totalPayout1 = Object.entries(reportTxns).reduce(
    (prev, [_, { payout }]) => payout + prev,
    0
  );

  const { commission = 0, fight = 0 } = ownAgent;
  const payoutFight = totalPayout1 * (fight / 100);
  const salesFight = totalSales * (fight / 100);

  const netPayout = totalPayout1 - payoutFight;
  const netSales = totalSales - salesFight;

  const totalCommission1 = netSales * (commission / 100);
  const netAmount = netSales - totalCommission1;
  const totalAmount1 = netAmount - netPayout;

  return {
    ...defaultProps,
    uplineAmount: formatAmount(totalAmount1),
    earning: formatAmount(totalAmount - totalAmount1),
  };
};

const groupbyReportTxns = (reportTxns) => {
  return Object.entries(reportTxns).map(([userName, txn]) => {
    const { user, sales, payout } = txn || {};

    const { commission: comm = 0, fight: fgt = 0 } = user || {};

    const payoutFight = payout * (fgt / 100);
    const salesFight = sales * (fgt / 100);

    const netPayout = payout - payoutFight;
    const netSales = sales - salesFight;

    const commission = netSales * (comm / 100);
    const netAmount = netSales - commission;
    const total = netAmount - netPayout;

    return {
      sales,
      payout: -netPayout,
      commission: formatAmount(commission),
      fight: formatAmount(salesFight),
      total: formatAmount(total),
      netAmount: formatAmount(netAmount),
      userName,
    };
  });
};

const sumGroupbyReport = (groupbyTxns) => {
  return groupbyTxns.reduce(
    (prev, { sales, commission, payout, fight, total, netAmount }) => {
      return {
        totalSales: prev.totalSales + sales,
        totalCommission: prev.totalCommission + commission,
        totalPayout: prev.totalPayout + payout,
        totalFight: prev.totalFight + fight,
        totalAmount: prev.totalAmount + total,
        totalNetAmount: prev.totalNetAmount + netAmount,
      };
    },
    {
      totalSales: 0,
      totalCommission: 0,
      totalPayout: 0,
      totalFight: 0,
      totalAmount: 0,
      totalNetAmount: 0,
    }
  );
};

export const sumUplineAndEarning = (txns) => {
  return txns.reduce(
    (prev, { uplineAmount, earning }) => {
      return {
        uplineAmount:
          uplineAmount !== undefined
            ? formatAmount(prev.uplineAmount + uplineAmount)
            : undefined,
        earning:
          earning !== undefined
            ? formatAmount(prev.earning + earning)
            : undefined,
      };
    },
    {
      uplineAmount: 0,
      earning: 0,
    }
  );
};
