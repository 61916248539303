import React, { useState } from "react";
import { useHistory } from "react-router";
import Table from "../components/Table";
import { useAppContext } from "../context/AppContext";
import useMount from "../hooks/useMount";
import Filter from "./Filter";
import EndMatch from "./EndMatch";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";

import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import useMatch from "../hooks/api/useMatch";
import Loader from "../components/Loader";
import { formatStandard } from "../helpers/DateTime";
import Toggle from "./Toggle";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";

import TableChartIcon from "@material-ui/icons/TableChart";

const StatusContent = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const Name = styled.span(({ head, theme }) => ({
  flex: 1,
  paddingLeft: head ? theme.spacing(5) : 0, // theme.spacing(1),
  paddingRight: head ? 0 : theme.spacing(5),
}));

const OffIcon = styled(ToggleOffIcon)(({ active }) => ({
  color: !active ? red[900] : green[500],
}));

const OnIcon = styled(ToggleOnIcon)(({ active }) => ({
  color: !active ? red[900] : green[500],
}));

function Matchs() {
  const { setPageTitle } = useAppContext();
  const history = useHistory();
  const [showFilter, setShowFilter] = useState();
  const [endMatch, setEndMatch] = useState();
  const [toggleItem, setToggleItem] = useState();

  const { response, loading, refresh, search } = useMatch({
    auto: true,
  });

  useMount(() => {
    setPageTitle("Match");
  });

  const headCells = [
    {
      id: "name",
      numeric: false,
      label: "Name",
      custom: renderNameAndAction,
    },
    {
      id: "playAt",
      numeric: false,
      label: "Match Date",
      format: (item) => formatStandard(item.playAt),
    },
    {
      id: "score",
      numeric: false,
      label: "Score",
      format: ({ score }) => score ?? "Playing",
    },
    {
      id: "createdAt",
      numeric: false,
      label: "Created At",
      format: (item) => formatStandard(item.createdAt),
    },
  ];

  function renderNameAndAction(item) {
    const { id, name, score, status } = item;
    const active = status === "active";
    const color = !active ? red[900] : green[500];
    return (
      <StatusContent>
        <Name>{name}</Name>
        {!score && (
          <IconButton
            title="End Match"
            color="primary"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setEndMatch(item);
            }}
          >
            <PowerSettingsNewIcon />
          </IconButton>
        )}
        <IconButton
          title={"Bet Report"}
          size="small"
          color={color}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            history.push(`/report/bet/${id}`);
          }}
        >
          <TableChartIcon />
        </IconButton>
        <IconButton
          title={active ? "Close Match" : "Open Match"}
          size="small"
          color={color}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setToggleItem(item);
          }}
        >
          {active ? <OnIcon active={active} /> : <OffIcon active={active} />}
        </IconButton>
      </StatusContent>
    );
  }
  const filterProps = {
    onClose: () => setShowFilter(),
    open: showFilter,
    onSearch: async (data) => {
      setShowFilter();
      if (!data) {
        await refresh();
      } else {
        await search(data);
      }
    },
  };

  const tableProps = {
    headCells,
    items: response,
    rowsPerPage: 10,
    title: "Matches",
    uniqueId: "username",
    defaultSort: "createdAt",
    defaultOrder: "desc",
    onAdd: () => {
      history.push("/match/add");
    },
    onFilter: () => {
      setShowFilter(true);
    },
    onSelect: ({ id }) => {
      history.push(`/match/${id}`);
    },
  };

  return (
    <>
      {loading ? <Loader paper /> : <Table {...tableProps} />}
      <Filter {...filterProps} />
      <EndMatch
        onClose={async () => {
          setEndMatch();
          await refresh();
        }}
        open={!!endMatch}
        match={endMatch}
      />

      <Toggle
        item={toggleItem}
        open={!!toggleItem}
        onClose={() => setToggleItem()}
        onConfirm={() => refresh()}
      />
    </>
  );
}

export default Matchs;
