import React, { useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router";
import Loader from "./components/Loader";
import Snackbar from "./components/Snackbar";
import { useAppContext } from "./context/AppContext";
import { useAuthContext } from "./context/AuthContext";
import Login from "./login/Login";
import Root from "./root/Root";
import styled from "styled-components";

const Main = styled.div({
  width: "100%",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

function App() {
  const { message, setMessage } = useAppContext();
  const { loading, isLogged } = useAuthContext();
  const { pathname, search } = useLocation();

  const messageProps = { message, onClose: () => setMessage() };

  if (loading) {
    return (
      <Main>
        <Loader />
      </Main>
    );
  }

  function renderContent() {
    return isLogged ? (
      <Root />
    ) : (
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Redirect
          to={{
            pathname: "/login",
            state: { from: `${pathname}${search}` },
          }}
        />
      </Switch>
    );
  }

  return (
    <>
      {renderContent()}
      <Snackbar {...messageProps} />
    </>
  );
}

export default App;
