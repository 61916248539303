import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { FormProvider } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import bgImage from "../assets/background.jpg";
import logoImage from "../assets/logo.png";
import Copyright from "../components/Copyright";
import Loader from "../components/Loader";
import TextField from "../components/TextField";
import { useAppContext } from "../context/AppContext";
import { useAuthContext } from "../context/AuthContext";
import { REQUIRED_RULE } from "../helpers/Form";
import useForm from "../hooks/useForm";

const UsernameInput = styled(TextField).attrs({
  label: "Username",
  name: "username",
  rules: REQUIRED_RULE,
  autoFocus: true,
})({});

const PasswordInput = styled(TextField).attrs({
  type: "password",
  label: "Password",
  name: "password",
  rules: REQUIRED_RULE,
})({});

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    width: 150,
    margin: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function LogIn() {
  const classes = useStyles();
  const form = useForm();
  const { handleSubmit } = form;

  const { signIn } = useAuthContext();
  const [loading, setLoading] = useState();
  const { setMessage } = useAppContext();

  const history = useHistory();

  const { state } = useLocation();
  const { from: fromLocation } = state || {};

  async function onSubmit(data) {
    setLoading(true);

    const { username, password } = data;
    const email = `${username}`.toLowerCase();
    try {
      const creds = { email, password };
      await signIn(creds);
      if (fromLocation) {
        history.replace(fromLocation);
      }
    } catch (ex) {
      setMessage(ex);
    }

    setLoading(false);
  }

  function renderForm() {
    return (
      <FormProvider {...form}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <UsernameInput />
            </Grid>
            <Grid item xs={12}>
              <PasswordInput />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In
              </Button>
            </Grid>
          </Grid>
          <Box mt={5}>
            <Copyright />
          </Box>
        </form>
      </FormProvider>
    );
  }

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img className={classes.logo} src={logoImage} alt="logo" />
          {loading ? <Loader /> : renderForm()}
        </div>
      </Grid>
    </Grid>
  );
}

export default LogIn;
