export const ROLE = {
  admin: "admin",
  master: "master",
  report: "report",
  agent: "agent",
};

export const ALLOW_ROLES = {
  [ROLE.admin]: [ROLE.master, ROLE.admin],
  [ROLE.master]: [ROLE.agent, ROLE.report],
  [ROLE.agent]: [ROLE.agent],
};
