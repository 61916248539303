import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

function useReport() {
  const { response, loading, get } = useAuthFetch({});

  function refresh(code) {
    const url = `${API_URL}/report/${code}`;
    return get({ url });
  }

  return { response, loading, refresh };
}

export default useReport;
