import {
  getReportParams,
  sumGroupbyReportTxns,
  sumUplineAndEarning,
} from "../../helpers/Report";
import useReport from "./useReport";
import { useState } from "react";

function useReports() {
  const { refresh: codeRefresh } = useReport();

  const [response, setResponse] = useState();
  const [loading, setLoading] = useState();

  async function refresh(matchNos) {
    setLoading(true);
    const matchs = matchNos
      .split(",")
      .map((s) => `${s}`.trim())
      .filter((s) => !!s);
    const allapis = matchs.map(async (matchNo) => {
      try {
        const api = await codeRefresh(matchNo);

        return api;
      } catch (ex) {}
    });

    const allReportsApi = await Promise.all(allapis);

    const cleanReports = allReportsApi.filter((s) => !!s);

    const gropupBy = sumGroupbyReportTxns(cleanReports.flatMap((s) => s.txns));
    const uplineReport = sumUplineAndEarning(cleanReports);

    const report = getReportParams([], "", gropupBy);

    const result = {
      ...report,
      ...uplineReport,
      matchs,
    };

    setResponse(result);
    setLoading(false);

    return result;
  }

  return { response, loading, refresh };
}

export default useReports;
