import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import LibCard from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import AppDivider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useParams } from "react-router";
import styled from "styled-components";
import DatePicker from "../components/Datepicker";
import Loader from "../components/Loader";
import TextField from "../components/TextField";
import { useAppContext } from "../context/AppContext";
import { REQUIRED_RULE } from "../helpers/Form";
import useMatch from "../hooks/api/useMatch";
import useForm from "../hooks/useForm";
import EndMatch from "../match/EndMatch";
import Toggle from "../match/Toggle";
import Dropzone from "../components/Dropzone";
import InputAdornment from "@material-ui/core/InputAdornment";
import MatchDetailLimit from "./MatchDetail.Limit";

const DividerContent = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

const Divider = styled(AppDivider)(({ theme }) => ({
  flex: 1,
}));

const DividerText = styled(Typography).attrs(({ theme }) => ({
  variant: "overline",
  display: "block",
  align: "center",
  color: "primary",
}))(({ theme }) => ({
  margin: theme.spacing(2),
}));

const ImageInput = styled(Dropzone).attrs({
  name: "image",
  label: "Image",
  url: "/match/image",
})({});

const HomeInput = styled(TextField).attrs({
  name: "home",
  label: "Home",
  rules: REQUIRED_RULE,
})({});

const AwayInput = styled(TextField).attrs({
  name: "away",
  label: "Away",
  rules: REQUIRED_RULE,
})({});

const MatchNoInput = styled(TextField).attrs({
  name: "matchNo",
  label: "Match No",
  rules: REQUIRED_RULE,
})({});

const ScoreInput = styled(TextField).attrs({
  name: "score",
  label: "Score",
  rules: REQUIRED_RULE,
})({});

const PlayAtInput = styled(DatePicker).attrs({
  name: "playAt",
  label: "Match Date",
  rules: REQUIRED_RULE,
  time: true,
})({});

const OddInput = styled(TextField).attrs({
  rules: REQUIRED_RULE,
  margin: "dense",
  type: "tel",
})({});

const Gap = styled.div({
  flex: 1,
});

const HOME_ODS = [
  "1-0",
  "2-0",
  "2-1",
  "3-0",
  "3-1",
  "3-2",
  "4-0",
  "4-1",
  "4-2",
  "4-3",
];
const AWAY_ODS = [
  "0-1",
  "0-2",
  "1-2",
  "0-3",
  "1-3",
  "2-3",
  "0-4",
  "1-4",
  "2-4",
  "3-4",
];
const DRAW_ODS = ["0-0", "1-1", "2-2", "3-3", "4-4", "AOS"];

const Card = styled(LibCard)({
  //   marginBottom: 16,
});

function MatchDetail() {
  const { setPageTitle, setMessage } = useAppContext();
  const { id } = useParams();
  const { response, loading, update, refresh } = useMatch({ id, auto: true });
  const [endMatch, setEndMatch] = useState();
  const [showConfirmation, setShowConfirmation] = useState();

  const {
    score,
    name,
    home,
    away,
    status,
    homeOddsLimit: oriHomeOddsLimit = [],
    awayOddsLimit: oriAwayOddsLimit = [],
    drawOddsLimit: oriDrawOddsLimit = [],
  } = response ?? {};
  const isActive = status === "active";

  const [toggleLimit, setToggleLimit] = useState(false);

  const form = useForm();
  const { handleSubmit, reset } = form;

  const inputProps = { disabled: !!score };

  useEffect(() => {
    setPageTitle(`Match - ${name}`);
  }, [name, setPageTitle]);

  useEffect(() => {
    reset(response);
  }, [response, reset]);

  async function onSubmit(data) {
    const {
      homeOdds,
      awayOdds,
      drawOdds,
      homeOddsLimit,
      awayOddsLimit,
      drawOddsLimit,
      ...rest
    } = data;

    const formatted = {
      ...rest,
      homeOdds: homeOdds.map((odd, index) => ({
        ...odd,
        score: HOME_ODS[index],
      })),
      awayOdds: awayOdds.map((odd, index) => ({
        ...odd,
        score: AWAY_ODS[index],
      })),
      drawOdds: drawOdds.map((odd, index) => ({
        ...odd,
        score: DRAW_ODS[index],
      })),

      homeOddsLimit: homeOddsLimit.map(({ limit = 0 }, index) => {
        const oriLimit = oriHomeOddsLimit[index] ?? {
          currentLimit: 0,
          limit: 0,
        };

        return {
          ...oriLimit,
          score: HOME_ODS[index],
          limit,
          currentLimit: !limit
            ? null
            : (oriLimit?.currentLimit || 0) + (limit - (oriLimit?.limit || 0)),
        };
      }),
      awayOddsLimit: awayOddsLimit.map(({ limit }, index) => {
        const oriLimit = oriAwayOddsLimit[index] ?? {};

        return {
          ...oriLimit,
          score: AWAY_ODS[index],
          limit,
          currentLimit: !limit
            ? null
            : (oriLimit?.currentLimit || 0) + (limit - (oriLimit?.limit || 0)),
        };
      }),
      drawOddsLimit: drawOddsLimit.map(({ limit }, index) => {
        const oriLimit = oriDrawOddsLimit[index] ?? {};

        return {
          ...oriLimit,
          score: DRAW_ODS[index],
          limit,
          currentLimit: !limit
            ? null
            : (oriLimit?.currentLimit || 0) + (limit - (oriLimit?.limit || 0)),
        };
      }),
    };

    try {
      await update(formatted);
      setMessage("Match updated");
    } catch (ex) {
      setMessage({ message: "Update match fail" });
    }
  }

  function renderOdd(odd, index, formName) {
    return (
      <Grid key={`${formName}.${index}.amount`} item lg={2} xs={6} md={4}>
        <OddInput
          {...inputProps}
          label={odd}
          name={`${formName}.${index}.amount`}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">x </InputAdornment>
            ),
          }}
        />
      </Grid>
    );
  }

  function renderDivider(title) {
    return (
      <DividerContent>
        <Divider />
        <DividerText>{title} Odd</DividerText>
        <Divider />
      </DividerContent>
    );
  }

  function renderDetail() {
    return (
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ImageInput {...inputProps} />
                </Grid>
                <Grid item lg={3} md={6} xs={12}>
                  <MatchNoInput {...inputProps} />
                </Grid>
                <Grid item lg={3} md={6} xs={12}>
                  <HomeInput {...inputProps} />
                </Grid>
                <Grid item lg={3} md={6} xs={12}>
                  <AwayInput {...inputProps} />
                </Grid>
                <Grid item lg={3} md={6} xs={12}>
                  <PlayAtInput {...inputProps} />
                </Grid>
                {score && (
                  <Grid item lg={3} md={6} xs={12}>
                    <ScoreInput {...inputProps} />
                  </Grid>
                )}
              </Grid>
              {toggleLimit ? renderLimit() : renderOdds()}
            </CardContent>
            {!score && (
              <CardActions>
                <Button type="submit" color="primary">
                  Update Match
                </Button>
                <Button color="primary" onClick={() => setEndMatch(true)}>
                  End Match
                </Button>
                <Button
                  color="secondary"
                  onClick={() => setShowConfirmation(true)}
                >
                  {isActive ? "Close Match" : "Open Match"}
                </Button>
                <Gap />
                <Button
                  color="textPrimary"
                  onClick={() => setToggleLimit((tg) => !tg)}
                >
                  {toggleLimit ? "Show Odd" : "Show Limit"}
                </Button>
              </CardActions>
            )}
          </Card>
        </form>
      </FormProvider>
    );
  }

  function renderOdds() {
    return (
      <>
        {renderDivider(home || "Home")}
        <Grid container spacing={2}>
          {HOME_ODS.map((item, index) => renderOdd(item, index, "homeOdds"))}
        </Grid>
        {renderDivider(away || "Away")}
        <Grid container spacing={2}>
          {AWAY_ODS.map((item, index) => renderOdd(item, index, "awayOdds"))}
        </Grid>
        {renderDivider("Draw")}
        <Grid container spacing={2}>
          {DRAW_ODS.map((item, index) => renderOdd(item, index, "drawOdds"))}
        </Grid>
      </>
    );
  }

  function renderLimit() {
    return <MatchDetailLimit form={form} />;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {loading ? <Loader paper /> : renderDetail()}
        </Grid>
      </Grid>
      <EndMatch
        onClose={async () => {
          setEndMatch();
          await refresh();
        }}
        open={!!endMatch}
        match={response}
      />

      <Toggle
        item={response}
        open={showConfirmation}
        onClose={() => setShowConfirmation()}
        onConfirm={() => refresh()}
      />
    </>
  );
}

export default MatchDetail;
