import { InputAdornment } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useParams } from "react-router";
import styled from "styled-components";
import Loader from "../components/Loader";
import PasswordPolicy from "../components/PasswordPolicy";
import Switch from "../components/Switch";
import TextField from "../components/TextField";
import { ROLE } from "../constants/Role";
import { useAppContext } from "../context/AppContext";
import { useAuthContext } from "../context/AuthContext";
import {
  MAX_RULE,
  MIN_RULE,
  PASSWORD_POLICY,
  REQUIRED_RULE,
} from "../helpers/Form";
import useAgent from "../hooks/api/useAgent";
import useAgentFrom from "../hooks/api/useAgentFrom";
import useUser from "../hooks/api/useUser";
import useForm from "../hooks/useForm";
import useMount from "../hooks/useMount";
import Credits from "../users/Credit";
import Toggle from "../users/Toggle";
import Users from "../users/Users";

const UsernameInput = styled(TextField).attrs({
  name: "id",
  label: "Username",
  rules: REQUIRED_RULE,
  disabled: true,
})({});

const FullnameInput = styled(TextField).attrs({
  name: "name",
  label: "Full Name",
  rules: REQUIRED_RULE,
})({});

const RoleInput = styled(TextField).attrs({
  name: "role",
  label: "Role",
  disabled: true,
})({});

const StatusInput = styled(TextField).attrs({
  name: "status",
  label: "Status",
  rules: REQUIRED_RULE,
  disabled: true,
})({});

const PasswordInput = styled(TextField).attrs({
  name: "password",
  label: "Password",
  type: "password",
  rules: { ...REQUIRED_RULE, pattern: PASSWORD_POLICY.pattern },
})({});

const CreditInput = styled(TextField).attrs({
  name: "credit",
  label: "Credit",
  type: "tel",
  disabled: true,
})({});

const CommissionSwitch = styled(Switch).attrs({
  name: "showCommission",
  label: "Show Commmission",
})({});

const CommissionInput = styled(TextField).attrs({
  name: "commission",
  label: "Commission %",
  type: "tel",
})({});

const FightSwitch = styled(Switch).attrs({
  name: "showFight",
  label: "Show Fight",
})({});

const FightInput = styled(TextField).attrs({
  name: "fight",
  label: "Fight %",
  type: "tel",
})({});

function UserDetail() {
  const { setPageTitle, setMessage } = useAppContext();
  const { user: authUser } = useAuthContext();
  const { id = "" } = useParams();

  const {
    response,
    loading: userLoading,
    refresh: userRefresh,
    update,
  } = useAgent({
    id,
    auto: true,
  });

  const {
    response: agentFrom,
    loading: agentFromLoading,
    refresh: agentFromRefresh,
  } = useAgentFrom({ id, auto: true });

  const loading = userLoading || agentFromLoading;
  const refresh = () => {
    userRefresh();
    agentFromRefresh();
  };

  const { id: userId, role: userRole } = authUser ?? {};

  const [toggleItem, setToggleItem] = useState();
  const [addCreditItem, setAddCreditItem] = useState();

  const { status, role, agentFrom: upline } = response ?? {};

  const isAdminRole = userRole === ROLE.admin;
  const isMasterRole = userRole === ROLE.master;

  const isAdmin = role === ROLE.admin;
  const isMaster = role === ROLE.master;

  const isDownLine = upline === userId;
  const isMe = userId === id;

  const showUpdate = isMe || isDownLine;

  const form = useForm();
  const pwdForm = useForm();

  const { watch } = pwdForm;
  const { watch: formWatch } = form;
  const { password } = watch();
  const { showCommission: showComm, showFight: showF } = formWatch();

  useMount(() => {
    setPageTitle(`User Management - ${id}`);
  });

  useEffect(() => {
    pwdForm.reset(response);
    form.reset(response);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  async function onSubmit({
    password,
    showCommission,
    showFight,
    commission,
    fight,
    ...data
  }) {
    try {
      await update({
        ...data,
        fight: !showFight ? 0 : fight,
        commission: !showCommission ? 0 : commission,
        showCommission: !!showCommission,
        showFight: !!showFight,
      });
      setMessage("User updated");
    } catch (ex) {
      const { message = "Update user fail" } = ex;
      setMessage({ message });
      refresh();
    }
  }

  async function onPwdSubmit({ password }) {
    try {
      await update({ password });
      setMessage("User updated");
    } catch (ex) {
      setMessage({ message: "Update user fail" });
    }
  }

  const creditProps = {
    onClose: () => setAddCreditItem(),
    onAddCredit: refresh,
    item: addCreditItem,
    open: !!addCreditItem,
  };

  function getInputProps(limit, allowZero) {
    if (limit === undefined || (limit === 0 && allowZero)) {
      return {
        rules: {
          ...REQUIRED_RULE,
          ...MIN_RULE(0),
        },
      };
    }

    return {
      rules: {
        ...REQUIRED_RULE,
        ...MIN_RULE(0),
        ...MAX_RULE(limit),
      },
      InputProps: {
        endAdornment: <InputAdornment position="end">/{limit}</InputAdornment>,
      },
    };
  }

  function renderToggleInput(Switch, Input, value, uplineShow, inputShow) {
    if (isAdmin || isMaster) {
      return;
    }

    const inputProps = {
      disabled: !isDownLine,
    };

    if (!uplineShow) {
      return;
    }

    return (
      <Grid item xs={12}>
        {isDownLine && <Switch />}
        {uplineShow && inputShow && (
          <Input
            {...getInputProps(
              isAdmin || isMaster ? 0 : value,
              isAdminRole || isMasterRole
            )}
            {...inputProps}
          />
        )}
      </Grid>
    );
  }

  function renderDetail() {
    const {
      commission,
      fight,
      showCommission = true,
      showFight = true,
    } = agentFrom ?? {};

    const fullNameProps = {
      disabled: !showUpdate,
    };

    return (
      <FormProvider {...form}>
        <Card component="form" onSubmit={form.handleSubmit(onSubmit)}>
          <CardContent>
            <Grid container spacing={2}>
              {!isAdmin && (
                <Grid item xs={12}>
                  <CreditInput />
                </Grid>
              )}
              <Grid item xs={12}>
                <RoleInput />
              </Grid>
              <Grid item xs={12}>
                <FullnameInput {...fullNameProps} />
              </Grid>
              {renderToggleInput(
                CommissionSwitch,
                CommissionInput,
                commission,
                showCommission,
                showComm
              )}
              {renderToggleInput(
                FightSwitch,
                FightInput,
                fight,
                showFight,
                showF
              )}
            </Grid>
          </CardContent>
          <CardActions>
            {showUpdate && (
              <Button type="submit" color="primary">
                Update
              </Button>
            )}
            {isDownLine && (
              <Button
                color="primary"
                onClick={(e) => {
                  setAddCreditItem(response);
                }}
              >
                Add Credit
              </Button>
            )}
          </CardActions>
        </Card>
      </FormProvider>
    );
  }

  function renderPassword() {
    const policyProps = {
      value: password,
      isSubmitted: false,
    };

    return (
      <FormProvider {...pwdForm}>
        <Card component="form" onSubmit={pwdForm.handleSubmit(onPwdSubmit)}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <UsernameInput />
              </Grid>
              <Grid item xs={12}>
                <StatusInput />
              </Grid>
              {showUpdate && (
                <Grid item xs={12}>
                  <PasswordInput />
                  <PasswordPolicy {...policyProps} />
                </Grid>
              )}
            </Grid>
          </CardContent>
          <CardActions>
            {showUpdate && (
              <Button type="submit" color="primary">
                Update Password
              </Button>
            )}

            {isDownLine && (
              <Button
                color="secondary"
                onClick={(e) => {
                  setToggleItem(response);
                }}
              >
                {status === "active" ? "Disable " : "Enable "} User
              </Button>
            )}
          </CardActions>
        </Card>
      </FormProvider>
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          {loading ? <Loader paper /> : renderPassword()}
        </Grid>
        <Grid item xs={12} lg={6}>
          {loading ? <Loader paper /> : renderDetail()}
        </Grid>
        <Grid item xs={12}>
          <Users id={id} showUpdate={isMe} />
        </Grid>
      </Grid>
      <Credits {...creditProps} />
      <Toggle
        item={toggleItem}
        open={!!toggleItem}
        onClose={() => setToggleItem()}
        onConfirm={() => refresh()}
      />
    </>
  );
}

export default UserDetail;
