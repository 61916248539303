import AppDivider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { FormProvider } from "react-hook-form";
import styled from "styled-components";
import TextField from "../components/TextField";
import { REQUIRED_RULE } from "../helpers/Form";

const HOME_ODS = [
  "1-0",
  "2-0",
  "2-1",
  "3-0",
  "3-1",
  "3-2",
  "4-0",
  "4-1",
  "4-2",
  "4-3",
];
const AWAY_ODS = [
  "0-1",
  "0-2",
  "1-2",
  "0-3",
  "1-3",
  "2-3",
  "0-4",
  "1-4",
  "2-4",
  "3-4",
];
const DRAW_ODS = ["0-0", "1-1", "2-2", "3-3", "4-4", "AOS"];

const DividerContent = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

const Divider = styled(AppDivider)(({ theme }) => ({
  flex: 1,
}));

const DividerText = styled(Typography).attrs(({ theme }) => ({
  variant: "overline",
  display: "block",
  align: "center",
  color: "primary",
}))(({ theme }) => ({
  margin: theme.spacing(2),
}));

const OddInput = styled(TextField).attrs({
  rules: REQUIRED_RULE,
  margin: "dense",
  type: "tel",
})({});

function AddMatchLimit({ form }) {
  const { watch } = form;
  const { home, away } = watch();

  function renderOdd(odd, index, formName) {
    const name = `${formName}.${index}.limit`;
    return (
      <Grid key={name} item lg={2} xs={6} md={4}>
        <OddInput
          label={odd}
          name={name}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">$ </InputAdornment>
            ),
          }}
        />
      </Grid>
    );
  }

  function renderDivider(title) {
    return (
      <DividerContent>
        <Divider />
        <DividerText>{title} Limit</DividerText>
        <Divider />
      </DividerContent>
    );
  }

  return (
    <FormProvider {...form}>
      {renderDivider(home || "Home")}
      <Grid container spacing={2}>
        {HOME_ODS.map((item, index) => renderOdd(item, index, "homeOddsLimit"))}
      </Grid>
      {renderDivider(away || "Away")}
      <Grid container spacing={2}>
        {AWAY_ODS.map((item, index) => renderOdd(item, index, "awayOddsLimit"))}
      </Grid>
      {renderDivider("Draw")}
      <Grid container spacing={2}>
        {DRAW_ODS.map((item, index) => renderOdd(item, index, "drawOddsLimit"))}
      </Grid>
    </FormProvider>
  );
}

export default AddMatchLimit;
