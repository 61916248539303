import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

function useActiveMatchs({
  id,
  auto,
  activeOnly = true,
  liteOnly = false,
} = {}) {
  const url = `${API_URL}/match/all${
    id ? `/${id}` : ""
  }${`?activeOnly=${activeOnly}&liteOnly=${liteOnly}`}`;
  const { response, loading, get } = useAuthFetch(auto ? { url } : {});

  function refresh() {
    return get({ url });
  }

  return { response, loading, refresh };
}

export default useActiveMatchs;
