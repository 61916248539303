import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

function useAgents({ id, auto } = {}) {
  const prefix = `${API_URL}/user`;
  const url = id ? `${prefix}/${id}/agents` : "";
  const { response, loading, get, post } = useAuthFetch(auto ? { url } : {});

  function refresh() {
    return get({ url });
  }

  function search(body) {
    return post({
      body: {
        ...body,
        dateOnly: true,
      },
      url: `${url}/search`,
    });
  }

  return { response, loading, refresh, search };
}

export default useAgents;
