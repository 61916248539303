import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

function useTxn({ id, auto } = {}) {
  const prefix = `${API_URL}/txn`;
  const url = id ? `${prefix}/${id}` : prefix;
  const { response, loading, get, post, put } = useAuthFetch(
    auto ? { url } : {}
  );

  function refresh() {
    return get({ url });
  }

  function create(body) {
    return post({
      body,
      url,
    });
  }

  function update(body) {
    return put({
      body,
      url,
    });
  }

  function search(body) {
    return post({
      body,
      url: `${url}/search`,
    });
  }

  return { response, loading, refresh, search, create, update };
}

export default useTxn;
