import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

function useDateReport() {
  const { response, loading, post } = useAuthFetch({});

  function refresh(body) {
    const url = `${API_URL}/report/search/day`;
    return post({
      url,
      body,
    });
  }

  return { response, loading, refresh };
}

export default useDateReport;
