import Auth from "@aws-amplify/auth";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
// import UserNotVerifiedException from "../errors/UserNotVerifiedException";
import { getAccessToken, getCurrentUser } from "../helpers/Auth";

import useCache from "../hooks/useCache";

export const AuthContext = createContext();

const authReducer = (prevState, { type, token, user }) => {
  switch (type) {
    case "RESTORE_TOKEN":
      return {
        ...prevState,
        isLogged: !!token,
        loading: false,
        userToken: token,
        user,
      };

    case "SIGN_IN":
      return {
        ...prevState,
        isLogged: true,
        loading: false,
        userToken: token,
        user,
      };
    case "SIGN_OUT":
      return {
        ...prevState,
        isLogged: false,
        loading: false,
        userToken: null,
        user: null,
      };
    default:
      throw new Error(`Unhandled type: ${type}`);
  }
};

function AuthContextProvider(props) {
  const [state, dispatch] = useReducer(authReducer, {
    isLogged: false,
    loading: true,
  });

  const { clear: clearCache } = useCache();

  const getUserDetails = useCallback(async (bypassCache) => {
    try {
      const token = await getAccessToken(bypassCache);
      const user = await getCurrentUser();
      return { token, user };
    } catch (ex) {
      return;
    }
  }, []);

  useEffect(() => {
    const initToken = async () => {
      const user = await getUserDetails();
      dispatch({ type: "RESTORE_TOKEN", ...user });
    };

    initToken();
  }, [getUserDetails]);

  async function signIn({ email, password }) {
    await Auth.signIn(email, password);
    const { token, user } = await getUserDetails(true);
    dispatch({ type: "SIGN_IN", token, user });
  }

  async function signOut() {
    await Auth.signOut();
    clearCache();
    dispatch({ type: "SIGN_OUT" });
  }

  async function changePassword({ newPassword, password }) {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, password, newPassword);
  }

  async function refreshUser() {
    const updated = await getUserDetails(true);
    dispatch({ type: "RESTORE_TOKEN", ...updated });
  }

  async function refreshSession() {
    const cognitoUser = await Auth.currentAuthenticatedUser();

    return new Promise(async (resolve, reject) => {
      const currentSession = cognitoUser.signInUserSession;
      cognitoUser.refreshSession(
        currentSession.refreshToken,
        (err, session) => {
          if (err) {
            reject(err);
          }

          resolve(session);
        }
      );
    });
  }

  async function refreshToken() {
    await refreshSession();
    const updated = await getUserDetails(true);
    dispatch({ type: "RESTORE_TOKEN", ...updated });
    return updated;
  }

  const value = {
    ...state,
    signIn,
    signOut,
    changePassword,
    refreshUser,
    refreshToken,
  };
  const ctxProps = { ...props, value };
  return <AuthContext.Provider {...ctxProps} />;
}

export const useAuthContext = () => useContext(AuthContext);

export default AuthContextProvider;
