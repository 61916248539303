import { Paper } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import styled from "styled-components";

const Container = styled.div({
  minHeight: 250,
  minWidth: 250,
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

function Loader({ paper }) {
  if (paper) {
    return <Paper>{render()}</Paper>;
  }

  function render() {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  return render();
}

export default Loader;
