import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";
// import useFetch from "../useFetch";

function useAgentFrom({ id, auto } = {}) {
  const prefix = `${API_URL}/user/agentFrom`;
  const url = id ? `${prefix}/${id}` : prefix;
  const { response, loading, get } = useAuthFetch(auto ? { url } : {});

  function refresh() {
    return get({ url });
  }

  return { response, loading, refresh };
}

export default useAgentFrom;
