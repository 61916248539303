import "date-fns";

import Auth from "@aws-amplify/auth";
import React, { StrictMode } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, StylesProvider } from "@material-ui/core/styles";
import "./index.css";
import App from "./App";
import theme from "./theme";
import reportWebVitals from "./reportWebVitals";
import SharedContextProvider from "./context/SharedContext";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  AWS_REGION,
  AUTH_USER_POOL_ID,
  AUTH_USER_POOL_WEB_CLIENT_ID,
  AUTH_OAUTH_URL,
  AUTH_REDIRECT_URL,
  AUTH_IDENTITY_POOL_ID,
} from "./constants/EnvConfig";

Auth.configure({
  Auth: {
    identityPoolId: AUTH_IDENTITY_POOL_ID,
    region: AWS_REGION,
    identityPoolRegion: AWS_REGION,
    userPoolId: AUTH_USER_POOL_ID,
    userPoolWebClientId: AUTH_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: AUTH_OAUTH_URL,
      scope: ["openid", "profile"],
      redirectSignIn: AUTH_REDIRECT_URL,
      redirectSignOut: AUTH_REDIRECT_URL,
      responseType: "code",
    },
  },
});

ReactDOM.render(
  <StylesProvider injectFirst>
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <SharedContextProvider>
            <CssBaseline />
            <StrictMode>
              <Router>
                <App />
              </Router>
            </StrictMode>
          </SharedContextProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  </StylesProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
