import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#ffc107",
    },
    error: {
      main: red.A400,
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        "&:-webkit-autofill": {
          transitionDelay: "9999s",
        },
      },
    },
    MuiInputAdornment: {
      root: {
        color: 'rgba(255,255,255,0.7)'
      }
    }
    //   MuiDropzoneArea: {
    //     root: {
    //       minHeight: 120,
    //       paddingTop: 16,
    //       paddingBottom: 16,
    //       borderWidth: 1,
    //       borderColor: "rgba(255, 255, 255, 0.23)",
    //       display: "flex",
    //       alignItems: "center",
    //       justifyContent: "center",
    //     },
    //     text: {
    //       fontSize: "1rem",
    //       color: "rgba(255, 255, 255, 0.7)",
    //       marginTop: 0,
    //       marginBottom: -36,
    //     },
    //     icon: {
    //       marginTop: -36,
    //       width: 32,
    //       height: 32,
    //       color: "rgba(255, 255, 255, 0.7)",
    //     },
    //   },
  },
});

export default theme;
