import React from "react";
import AppContextProvider from "./AppContext";
import AuthContextProvider from "./AuthContext";

function SharedContext({ children }) {
  return (
    <AppContextProvider>
      <AuthContextProvider>{children}</AuthContextProvider>
    </AppContextProvider>
  );
}

export default SharedContext;
