import { Grid, InputAdornment } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import styled from "styled-components";
import Loader from "../components/Loader";
import TextField from "../components/TextField";
import { ROLE } from "../constants/Role";
import { useAppContext } from "../context/AppContext";
import { useAuthContext } from "../context/AuthContext";
import { MAX_RULE, MIN_RULE, REQUIRED_RULE } from "../helpers/Form";
import useAgentFrom from "../hooks/api/useAgentFrom";
import useUser from "../hooks/api/useUser";

const CreditInput = styled(TextField).attrs({
  name: "credit",
  label: "Credit",
})({});

function Credits({ open, item, onAddCredit, onClose }) {
  const form = useForm();
  const { setMessage } = useAppContext();
  const { user } = useAuthContext();
  const { handleSubmit, reset } = form;

  const { id } = item ?? {};

  const { userLoading, update } = useUser({ id });
  const { meLoading, response: upperLine, refresh } = useAgentFrom({ id });

  useEffect(() => {
    if (open) {
      refresh();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const { credit } = upperLine ?? {};
  const loading = userLoading || meLoading;
  const { role } = user ?? {};
  const isAdminRole = role === ROLE.admin;

  async function onSubmit(data) {
    try {
      await update(data);
      onAddCredit();
      reset();
      onClose();
    } catch (ex) {
      const { message = "Unable to add credit " } = ex;
      setMessage({ message });
    }
  }

  function getInputProps(limit) {
    if (limit === undefined || isAdminRole) {
      return {
        rules: {
          ...REQUIRED_RULE,
          ...MIN_RULE(0),
        },
      };
    }

    return {
      rules: {
        ...REQUIRED_RULE,
        ...MIN_RULE(0),
        ...MAX_RULE(limit),
      },
      InputProps: {
        endAdornment: <InputAdornment position="end">/{limit}</InputAdornment>,
      },
    };
  }

  function renderContent() {
    return (
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Add Credit</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CreditInput {...getInputProps(credit)} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    );
  }

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      {loading ? <Loader /> : renderContent()}
    </Dialog>
  );
}

export default Credits;
