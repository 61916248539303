import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

export const ALL_SELECTION = ["Today Match", "Future Match"];

const Container = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  "& > *": {
    margin: theme.spacing(0.5),
  },
}));

function FilterChips({ selected: prev, onChange, items = ALL_SELECTION }) {
  const [selected, setSelected] = useState(prev ?? []);

  useEffect(() => {
    onChange?.(selected);
  }, [onChange, selected]);

  function updateSelected(item) {
    const found = selected.includes(item);
    if (!found) {
      setSelected((s) => [...s, item]);
    } else {
      setSelected((s) => s.filter((i) => i !== item));
    }
  }

  function renderAllChip() {
    const isSelect = selected.length === 0;
    const chipProps = {
      icon: isSelect ? <DoneIcon size="small" /> : null,
      color: isSelect ? "secondary" : "",
      label: "All",
      onClick: () => setSelected([]),
    };

    return <Chip {...chipProps}>All</Chip>;
  }

  return (
    <Container>
      {renderAllChip()}
      {items.map((item) => {
        const isSelect = selected.includes(item);
        const chipProps = {
          icon: isSelect ? <DoneIcon size="small" /> : null,
          color: isSelect ? "secondary" : "",
          label: item,
          clickable: true,
          onClick: () => updateSelected(item),
        };
        return <Chip {...chipProps}></Chip>;
      })}
    </Container>
  );
}

export default FilterChips;
