let cachedResponse = {};
let waitCacheResponse = {};
let resolveCacheResponse = {};

const useCache = (enabled) => {
  function store(key, res) {
    if (!enabled) {
      cachedResponse[key] = undefined;
      return;
    }

    cachedResponse[key] = res;

    if (waitCacheResponse[key]) {
      resolveCacheResponse[key](res);
      delete waitCacheResponse[key];
      delete resolveCacheResponse[key];
    }
  }

  async function get(key) {
    if (!enabled) {
      return;
    }

    if (waitCacheResponse[key]) {
      return waitCacheResponse[key];
    }

    const hasCache = cachedResponse[key];
    if (!hasCache) {
      return;
    }

    return hasCache;
  }

  function clear() {
    cachedResponse = {};
    resolveCacheResponse = {};
    waitCacheResponse = {};
  }

  function wait(key) {
    if (!enabled) {
      return;
    }

    waitCacheResponse[key] = new Promise((resolve, reject) => {
      resolveCacheResponse[key] = resolve;
    });
  }

  return {
    store,
    get,
    clear,
    wait,
  };
};

export default useCache;
