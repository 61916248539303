import LibTextField from "@material-ui/core/TextField";
import { useController, useFormContext } from "react-hook-form";
import IconButton from "@material-ui/core/IconButton";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useState } from "react";
import styled from "styled-components";
import { isEmpty } from "../helpers/String";

const AppTextField = styled(LibTextField)({
  // marginBottom: 16,
});

const TextField = ({
  label,
  name,
  type: pType = "text",
  rules,
  defaultValue = "",
  disabled,

  onBlur,

  ...rest
}) => {
  const [type, setType] = useState(pType);
  const [showPassword, setShowPassword] = useState(false);

  const { control } = useFormContext();

  const {
    field: { ref: inputRef, onChange, value },
    fieldState: { error: { message } = {}, invalid: error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const props = {
    ...rest,
    label,
    type,
    inputRef,
    onChange,
    onBlur: onInputBlur,
    value: `${isEmpty(value) ? "" : value}`,
    error,
    helperText: message,
    disabled,
    variant: "outlined",
    fullWidth: true,
  };

  function onInputBlur(e) {
    if (pType !== "tel" && pType !== "number") {
      onBlur && onBlur(e);
      return;
    }

    const valid = new RegExp(/^-?\d+(\.\d{0,2})?$/).test(value);
    const updated = valid ? +value : "";
    onChange(updated);
    onBlur && onBlur(e);
  }

  const pwdProps = {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => {
            setShowPassword((s) => !s);
            setType(showPassword ? "password" : "text");
          }}
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    ),
  };

  const inputProps =
    pType === "password" ? { ...props, InputProps: pwdProps } : props;

  return <AppTextField {...inputProps} />;
};

export default TextField;
