import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import mock1Image from "../assets/mock1.jpg";
// import mock2Image from "../assets/mock2.jpg";
// import mock3Image from "../assets/mock3.jpg";
// import mock4Image from "../assets/mock4.jpg";
import FilterChips from "../components/FilterChips";
import Loader from "../components/Loader";
import { ROLE } from "../constants/Role";
import { useAppContext } from "../context/AppContext";
import { useAuthContext } from "../context/AuthContext";
import { formatStandard } from "../helpers/DateTime";
import useMatch from "../hooks/api/useMatch";
import useUser from "../hooks/api/useUser";
import useMount from "../hooks/useMount";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 250,
  },
}));

function Home() {
  const { setPageTitle } = useAppContext();
  const { user } = useAuthContext();
  const { role: userRole } = user ?? {};

  const classes = useStyles();

  const [selectedFilter, setSelectedFilter] = useState([]);

  const { response: me } = useUser({
    auto: true,
    id: "me",
  });
  const { masterId, id: username } = me ?? {};
  const id = userRole === ROLE.master ? username : masterId;

  const {
    response = [],
    loading,
    refresh,
  } = useMatch({
    id: `all/${id}`,
    activeOnly: true,
  });

  useMount(() => {
    setPageTitle("Home");
  });

  useEffect(() => {
    if (!id) {
      return;
    }

    refresh();
  }, [id]);

  const onFilterItems = (filter) => {
    setSelectedFilter(filter);
  };

  const getFiltered = () => {
    if (selectedFilter.length === 0) {
      return response;
    }

    return response.filter(({ playAt }) => {
      const validToday = moment(playAt).isBetween(
        moment().startOf("day"),
        moment().endOf("day")
      );
      const validTomorrow = moment(playAt).isSameOrAfter(
        moment().add(1, "day").startOf("day")
      );

      if (selectedFilter.includes("Today Match") && validToday) {
        return true;
      }

      if (selectedFilter.includes("Future Match") && validTomorrow) {
        return true;
      }

      return false;
    });
  };

  function renderItem(item) {
    const { id, name, image, playAt } = item;
    return (
      <Grid item md={6} xs={12}>
        <Card>
          <CardActionArea component={Link} to={`/betting/${id}`}>
            <CardMedia className={classes.media} image={image} title={name} />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {name}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {formatStandard(playAt)}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    );
  }

  const filtered = getFiltered();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FilterChips onChange={onFilterItems} />
      </Grid>
      {loading ? <Loader paer /> : filtered.map(renderItem)}
    </Grid>
  );
}

export default Home;
