import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";
// import useFetch from "../useFetch";

function useAgent({ id, auto } = {}) {
  const prefix = `${API_URL}/user/agent`;
  const url = id ? `${prefix}/${id}` : prefix;
  const { response, loading, get, put } = useAuthFetch(auto ? { url } : {});

  function refresh() {
    return get({ url });
  }

  function update(body) {
    return put({
      body,
      url: `${API_URL}/user/${id}`,
    });
  }

  return { response, loading, refresh, update };
}

export default useAgent;
