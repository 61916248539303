import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import LibSelect from "@material-ui/core/Select";
import { useController, useFormContext } from "react-hook-form";

const Select = ({
  items = [],
  label,
  name,
  rules,
  defaultValue = "",
  disabled,
}) => {
  const { control } = useFormContext();

  const {
    field: { ref: inputRef, onChange, value },
    fieldState: { error: { message } = {}, invalid: error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const props = {
    label,
    inputRef,
    onChange,
    value,
    error,
    helperText: message,
    disabled,
    native: true,
  };

  return (
    <FormControl error={error} variant="outlined" fullWidth>
      <InputLabel>{label}</InputLabel>
      <LibSelect {...props}>
        {items.map((item) => (
          <option value={item.value}>{item.text}</option>
        ))}
      </LibSelect>
    </FormControl>
  );
};

export default Select;
