import React from "react";
import Confirmation from "../components/Confirmation";
import useMatch from "../hooks/api/useMatch";

function Toggle({ item, open, onClose, onConfirm }) {
  const { id, status } = item ?? {};
  const { loading, update } = useMatch({ id, auto: false });

  const active = status === "active";

  const conProps = {
    title: `${active ? "Close" : "Open"} Match ${item?.name}`,
    desc: `Are you sure want to ${active ? "close" : "open"} match now?`,
    open,
    onClose,
    loading,
    onConfirm: async () => {
      const status = active ? "inactive" : "active";
      await update({ status });
    },
  };
  return <Confirmation {...conProps} />;
}

export default Toggle;
