import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import React, { useState } from "react";
import { FormProvider } from "react-hook-form";
// import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import DatePicker from "../components/Datepicker";
import Loader from "../components/Loader";
import TextField from "../components/TextField";
import { ROLE } from "../constants/Role";
import { useAppContext } from "../context/AppContext";
import { useAuthContext } from "../context/AuthContext";
import { REQUIRED_RULE } from "../helpers/Form";
import { numberWithCommas } from "../helpers/String";
import useDateReports from "../hooks/api/useDateReports";
import useAdminReport from "../hooks/api/useReport.Admin";
import useReports from "../hooks/api/useReports";
import useForm from "../hooks/useForm";
import useMount from "../hooks/useMount";
import useQueryString from "../hooks/useQuerystring";

const MatchNoInput = styled(TextField).attrs({
  name: "matchNo",
  label: "Match No(s)",
  placeholder: "e.g, EPL000,EPL001,EPL002",
})({});

const MasterInput = styled(TextField).attrs({
  name: "master",
  label: "Master",
  rules: REQUIRED_RULE,
})({});

const StartDateInput = styled(DatePicker).attrs({
  name: "startDate",
  label: "Start Date",
})({});

const EndDateInput = styled(DatePicker).attrs({
  name: "endDate",
  label: "End Date",
})({});

const Value = styled(Typography).attrs({
  variant: "h3",
})(({ $error, $primary }) => ({
  marginBottom: 16,
  color: $primary ? "#ffc107" : $error ? red[300] : green[500],
  wordBreak: "break-word",
  textAlign: "center",
}));

const Title = styled(Typography).attrs({
  variant: "overline",
  color: "textSecondary",
})({
  paddingLeft: 8,
  paddingRight: 8,
});

const SummaryCard = styled(Paper)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  paddingTop: 32,
  paddingBottom: 16,
  paddingLeft: 16,
  paddingRight: 16,
  minHeight: 155,
  height: "100%",
});

function Report() {
  const { setPageTitle } = useAppContext();
  const form = useForm();
  const { handleSubmit, reset } = form;

  const { user } = useAuthContext();

  const [searchedMatchNos, setSearchedMatchNos] = useQueryString("mn");
  const [searchedStartedDate, setSearchedStartedDate] = useQueryString("sd");
  const [searchedEndDate, setSearchedEndDate] = useQueryString("ed");
  const [searchedMaster, setSearchedMaster] = useQueryString("m");

  const { role: userRole } = user ?? {};

  const isAdminRole = userRole === ROLE.admin;

  useMount(() => {
    setPageTitle("Report");

    reset({
      matchNo: searchedMatchNos,
      startDate: searchedStartedDate && +searchedStartedDate,
      endDate: searchedEndDate && +searchedEndDate,
      master: searchedMaster,
    });

    if (
      searchedMatchNos ||
      (searchedStartedDate && searchedEndDate) ||
      searchedMaster
    ) {
      handleSubmit(onSubmit)();
    }
  });

  const {
    response: codeReport,
    loading: codeLoading,
    refresh: codeRefresh,
  } = useReports();
  const {
    response: dateReport,
    loading: dateLoading,
    refresh: dateRefresh,
  } = useDateReports();

  const {
    response: adminReport,
    loading: adminReportLoading,
    refresh: adminReportRefresh,
  } = useAdminReport();

  const [searchingCode, setSearchingCode] = useState(true);

  const response = isAdminRole
    ? adminReport
    : searchingCode
    ? codeReport
    : dateReport;
  const loading = isAdminRole
    ? adminReportLoading
    : searchingCode
    ? codeLoading
    : dateLoading;

  const {
    matchs = [],
    home,
    away,
    totalSales,
    totalCommission,
    totalPayout,
    totalFight,
    totalAmount,
    uplineAmount,
    earning,
    txns = [],
  } = response ?? {};

  async function onSubmit(data) {
    const { matchNo, master, startDate: sd, endDate: ed } = data;

    const startDate = moment(sd).startOf("day");
    const endDate = moment(ed).endOf("day");

    setSearchedMaster(master);
    setSearchedMatchNos(matchNo);
    setSearchedStartedDate(sd && startDate.valueOf());
    setSearchedEndDate(ed && endDate.valueOf());

    setSearchingCode(!!matchNo);
    if (isAdminRole) {
      await adminReportRefresh({
        matchNo,
        master,
        startDate,
        endDate,
      });
      return;
    }

    if (matchNo) {
      await codeRefresh(matchNo);
      return;
    }

    if (startDate || endDate) {
      dateRefresh({
        startDate,
        endDate,
        matchs,
      });
      return;
    }
  }

  function onReset() {
    setSearchedMaster();
    setSearchedMatchNos();
    setSearchedStartedDate();
    setSearchedEndDate();
    form.reset({});
  }

  function formatAmount(amount = 0) {
    if (amount >= 0) {
      return `${numberWithCommas(amount)}`;
    }

    return `-${numberWithCommas(Math.abs(amount))}`;
  }

  function formatDp(amount, postfix) {
    if (amount === "") {
      return "";
    }
    const formtted = +(Math.round(amount * 100) / 100).toFixed(2);
    return isNaN(formtted) ? amount : `${formtted}${postfix}`;
  }

  function renderSearchContent() {
    return (
      <>
        <Grid item lg={6} xs={12}>
          <Card component="form" onSubmit={handleSubmit(onSubmit)}>
            <CardContent>
              <Grid container spacing={2}>
                {isAdminRole && (
                  <Grid item xs={12}>
                    <MasterInput />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <MatchNoInput />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <StartDateInput />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <EndDateInput />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Button color="primary" type="submit">
                Submit
              </Button>
              <Button color="secondary" onClick={onReset}>
                Reset
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid xs lg={3} />
        <Grid xs lg={3} />
      </>
    );
  }

  function renderSummary() {
    if (!response) {
      return null;
    }

    return (
      <>
        <Grid item xs={12} md={6} lg={4}>
          <SummaryCard>
            {home && (
              <Value $primary>
                {home}
                <Title>vs</Title>
                {away}
              </Value>
            )}
            {matchs.length !== 0 && (
              <>
                <Value $primary>
                  {matchs.length} <Title>match(s)</Title>
                </Value>
                <Title sub>{matchs.join(", ")}</Title>
              </>
            )}
          </SummaryCard>
        </Grid>
        {earning !== undefined && (
          <Grid item xs={12} md={6} lg={4}>
            <SummaryCard>
              <Value $error={earning < 0}>{formatAmount(earning)}</Value>
              <Title>Profit/Lost</Title>
            </SummaryCard>
          </Grid>
        )}
        <Grid item xs={12} md={6} lg={4}>
          <SummaryCard>
            <Value>{formatAmount(totalSales)}</Value>
            <Title> Total Sales ($)</Title>
          </SummaryCard>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <SummaryCard>
            <Value $error={totalFight < 0}>{formatAmount(totalFight)}</Value>
            <Title>Total Fight ($)</Title>
          </SummaryCard>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <SummaryCard>
            <Value $error>{formatAmount(-totalCommission)}</Value>
            <Title> Total Commission ($)</Title>
          </SummaryCard>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <SummaryCard>
            <Value $error>{formatAmount(totalPayout)}</Value>
            <Title>Total Payout ($)</Title>
          </SummaryCard>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <SummaryCard>
            <Value $error={totalAmount < 0}>{formatAmount(totalAmount)}</Value>
            <Title> Total Amount from Downline ($)</Title>
          </SummaryCard>
        </Grid>
        {uplineAmount !== undefined && (
          <Grid item xs={12} md={6} lg={4}>
            <SummaryCard>
              <Value $error={uplineAmount >= 0}>
                {formatAmount(uplineAmount)}
              </Value>
              <Title> Total Amount to submit Upline ($)</Title>
            </SummaryCard>
          </Grid>
        )}
      </>
    );
  }

  function renderTable() {
    if (txns.length === 0) {
      return null;
    }

    return (
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Username</TableCell>
                <TableCell align="right">Sales</TableCell>
                <TableCell align="right">Fight</TableCell>
                <TableCell align="right">Commission</TableCell>
                <TableCell align="right">Payout</TableCell>
                <TableCell align="right">Total</TableCell>
                <TableCell align="right">%</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {txns.map(
                ({
                  userName,
                  sales,
                  commission,
                  payout,
                  fight,
                  total,
                  perc,
                }) => (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {userName}
                    </TableCell>
                    <TableCell align="right">{formatAmount(sales)}</TableCell>
                    <TableCell align="right">{formatAmount(fight)}</TableCell>
                    <TableCell align="right">
                      {formatAmount(-commission)}
                    </TableCell>
                    <TableCell align="right">{formatAmount(payout)}</TableCell>
                    <TableCell align="right">{formatAmount(total)}</TableCell>
                    <TableCell align="right">{formatDp(perc, "%")}</TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    );
  }

  return (
    <FormProvider {...form}>
      <Grid container spacing={2}>
        {loading ? (
          <Grid item xs={12}>
            <Loader paper />
          </Grid>
        ) : (
          <>
            {renderSearchContent()}
            {renderSummary()}
            {renderTable()}
          </>
        )}
      </Grid>
    </FormProvider>
  );
}

export default Report;
