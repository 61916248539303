import { IconButton } from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import DeleteIcon from "@material-ui/icons/Delete";
import ReceiptIcon from "@material-ui/icons/Receipt";
import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";
import Receipt from "../betting/Receipt";
import Confirmation from "../components/Confirmation";
import Loader from "../components/Loader";
import Table from "../components/Table";
import { ROLE } from "../constants/Role";
import { useAppContext } from "../context/AppContext";
import { useAuthContext } from "../context/AuthContext";
import { formatStandard } from "../helpers/DateTime";
import useTxn from "../hooks/api/useTxn";
import useMount from "../hooks/useMount";
import Filter from "./Filter";

const CUTOFF_SEC = 60;

const StatusContent = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const Dot = styled.div(({ status, theme }) => ({
  width: 10,
  height: 10,
  borderRadius: "100%",
  backgroundColor: status === "void" ? red[900] : green[500],
  marginRight: theme.spacing(1),
}));

const Status = styled.div(({ head, theme }) => ({
  paddingLeft: head && 10 + theme.spacing(1),
  flex: 1,
  paddingRight: theme.spacing(5),
}));

function History() {
  const { user } = useAuthContext();
  const { role: userRole } = user ?? {};
  const isMasterRole = userRole === ROLE.master;
  const { setPageTitle, setMessage } = useAppContext();
  const [receipt, setReceipt] = useState();
  const [showFilter, setShowFilter] = useState();

  const [selected, setSelected] = useState();

  const { response, loading, refresh, search } = useTxn({ auto: true });
  const { loading: txnLoading, update: updateTxn } = useTxn({
    id: selected?.id,
  });

  useMount(() => {
    setPageTitle("Betting History");
  });

  const headCells = [
    {
      id: "matchName",
      numeric: false,
      label: "Match",
      custom: renderStatus,
      customHeader: renderStatusHeader,
    },
    {
      id: "matchAt",
      numeric: false,
      label: "Match At",
      format: ({ matchAt }) => formatStandard(matchAt),
    },
    { id: "paidBy", numeric: false, label: "Username" },
    { id: "totalAmount", numeric: true, label: "Total" },
    {
      id: "createdAt",
      numeric: false,
      label: "Created At",
      format: ({ createdAt }) => formatStandard(createdAt),
    },
  ];

  function isAllowToVoid(item) {
    const { matchName, status, matchAt, score } = item;
    if (status === "void") {
      return false;
    }

    if (isMasterRole) {
      return true;
    }

    return false;

    // TODO: ALLOW AGENT TO VOID
    // const { matchName, status, matchAt, score } = item;

    // const cutOff = moment(matchAt).subtract(CUTOFF_SEC, "seconds");
    // const expired = moment(cutOff).isSameOrBefore(moment());

    // return !score && status !== "void" && !expired
  }

  function renderStatusHeader(label) {
    return (
      <StatusContent>
        <Status head>{label}</Status>
      </StatusContent>
    );
  }

  function renderStatus(item) {
    const { matchName, status } = item;

    const isAllowVoid = isAllowToVoid(item);

    return (
      <StatusContent>
        <Dot status={status} />
        <Status>{matchName}</Status>
        {isAllowVoid && (
          <IconButton
            color="secondary"
            size="small"
            onClick={() => setSelected(item)}
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        )}
        <IconButton size="small" onClick={() => setReceipt(item)}>
          <ReceiptIcon fontSize="inherit" />
        </IconButton>
      </StatusContent>
    );
  }

  const filterProps = {
    onClose: () => setShowFilter(),
    open: showFilter,
    onSearch: async (data) => {
      setShowFilter();
      if (!data) {
        await refresh();
      } else {
        await search(data);
      }
    },
  };

  const tableProps = {
    headCells,
    items: response,
    rowsPerPage: 10,
    defaultOrder: "desc",
    uniqueId: "id",
    title: "Transactions",
    defaultSort: "createdAt",
    onFilter: () => {
      setShowFilter(true);
    },
  };

  return (
    <>
      {loading ? <Loader paper /> : <Table {...tableProps} />}
      <Receipt item={receipt} onClose={() => setReceipt()} open={!!receipt} />
      <Filter {...filterProps} />

      <Confirmation
        title={`Void Bet - ${selected?.matchName}`}
        desc={`Total Amount: $${selected?.totalAmount}. Are you sure want to void bet now?`}
        open={!!selected}
        loading={txnLoading}
        onClose={() => setSelected()}
        onConfirm={async () => {
          setSelected();
          try {
            await updateTxn({ status: "void" });
            await refresh();
          } catch (ex) {
            setMessage({ message: "Match Closed" });
          }
        }}
      />
    </>
  );
}

export default History;
