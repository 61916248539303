import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ChangePassword from "../password/Password";
import UserDetail from "../userDetail/UserDetail";
import AddUser from "../addUser/AddUser";
import SharedLayout from "../components/SharedLayout";
import { useAppContext } from "../context/AppContext";
import Betting from "../betting/Betting";
import Betting2 from "../betting/Betting.Master";
import BettingHistory from "../bettingHistory/History";
import Home from "../home/Home";
import Matchs2 from "../match/Match.Master";
import Matchs from "../match/Match";
import AddMatch from "../addMatch/AddMatch";
import Report from "../report/Report";
import BetReport from "../betReport/BetReport";
import MatchDetail from "../matchDetail/MatchDetail";
import { useAuthContext } from "../context/AuthContext";
import { ROLE } from "../constants/Role";

function Root() {
  const { pageTitle } = useAppContext();
  const { user } = useAuthContext();

  const { role } = user ?? {};
  const isAdmin = role === ROLE.admin;
  const isMaster = role === ROLE.master;
  const isReport = role === ROLE.report;

  return (
    <SharedLayout title={pageTitle}>
      <Switch>
        <Route exact path="/user/password">
          <ChangePassword />
        </Route>
        {!isReport && (
          <Route exact path="/user/management/add/:id?">
            <AddUser />
          </Route>
        )}
        {!isReport && (
          <Route exact path="/user/management/:id">
            <UserDetail />
          </Route>
        )}
        {!isReport && (
          <Route exact path="/user/management/:id/agent">
            <UserDetail agent />
          </Route>
        )}
        {!isReport && (
          <Route exact path="/betting/history">
            <BettingHistory />
          </Route>
        )}
        {!isAdmin && (
          <Route exact path="/betting/:id?">
            {isMaster ? <Betting2 /> : <Betting />}
          </Route>
        )}
        {isMaster && (
          <Route exact path="/match/add">
            <AddMatch />
          </Route>
        )}
        {isMaster && (
          <Route exact path="/match/:id">
            <MatchDetail />
          </Route>
        )}
        <Route exact path="/match">
          {isMaster ? <Matchs2 /> : <Matchs />}
        </Route>
        <Route exact path="/report/bet/:id">
          <BetReport />
        </Route>]
        <Route exact path="/report">
          <Report />
        </Route>
        {!isReport && <Route exact path="/">
          <Home />
        </Route>}
        {!isReport && <Redirect to="/" />}
        {isReport && <Redirect to="/report" />}
      </Switch>
    </SharedLayout>
  );
}

export default Root;
