import { makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import GamesIcon from "@material-ui/icons/Games";
import HistoryIcon from "@material-ui/icons/History";
import HomeIcon from "@material-ui/icons/Home";
import LockIcon from "@material-ui/icons/Lock";
import PeopleIcon from "@material-ui/icons/People";
import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ROLE } from "../constants/Role";
import { useAuthContext } from "../context/AuthContext";
import EventNoteIcon from "@material-ui/icons/EventNote";

export const DRAWER_WIDTH = 240;

const ToolbarIcon = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: "0 8px",
  ...theme.mixins.toolbar,
}));

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: DRAWER_WIDTH,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
  },
}));

function AppDrawer({ open, setOpen }) {
  const { user } = useAuthContext();

  const { role, id } = user ?? {};
  const isAdmin = role === ROLE.admin;
  const isMaster = role === ROLE.master;
  const isAgent = role === ROLE.agent;
  const isReport = role === ROLE.report;

  const classes = useStyles();
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const getMenus = () => {
    if (isAdmin) {
      return {
        userMenus: [
          {
            icon: LockIcon,
            text: "Change Password",
            url: "/user/password",
          },
          {
            icon: PeopleIcon,
            text: "User Management",
            url: `/user/management/${id}`,
          },
        ],
        gameMenus: [
          {
            icon: HistoryIcon,
            text: "Betting History",
            url: "/betting/history",
          },
          {
            icon: AssessmentIcon,
            text: "Report",
            url: "/report",
          },
        ],
      };
    }

    if (isMaster) {
      return {
        userMenus: [
          {
            icon: HomeIcon,
            text: "Home",
            url: "",
            notAdmin: true,
          },
          {
            icon: LockIcon,
            text: "Change Password",
            url: "/user/password",
          },
          {
            icon: PeopleIcon,
            text: "User Management",
            url: `/user/management/${id}`,
          },
        ],
        gameMenus: [
          {
            icon: GamesIcon,
            text: "Betting",
            url: "/betting",
            notAdmin: true,
          },
          {
            icon: HistoryIcon,
            text: "Betting History",
            url: "/betting/history",
          },
          {
            icon: AssessmentIcon,
            text: "Report",
            url: "/report",
          },
          {
            icon: EventNoteIcon,
            text: "Odds",
            url: "/match",
            notAdmin: true,
          },
        ],
      };
    }

    if (isAgent) {
      return {
        userMenus: [
          {
            icon: HomeIcon,
            text: "Home",
            url: "",
            notAdmin: true,
          },
          {
            icon: LockIcon,
            text: "Change Password",
            url: "/user/password",
          },
          {
            icon: PeopleIcon,
            text: "User Management",
            url: `/user/management/${id}`,
          },
        ],
        gameMenus: [
          {
            icon: GamesIcon,
            text: "Betting",
            url: "/betting",
            notAdmin: true,
          },
          {
            icon: HistoryIcon,
            text: "Betting History",
            url: "/betting/history",
          },
          {
            icon: AssessmentIcon,
            text: "Report",
            url: "/report",
          },

          {
            icon: EventNoteIcon,
            text: "Bet Report",
            url: "/match",
          },
        ],
      };
    }

    if (isReport) {
      return {
        userMenus: [],
        gameMenus: [
          {
            icon: AssessmentIcon,
            text: "Report",
            url: "/report",
          },
          {
            icon: EventNoteIcon,
            text: "Bet Report",
            url: "/match",
          },
        ],
      };
    }
  };

  const renderItem = ({ icon: Icon, text, url }) => {
    return (
      <ListItem button component={Link} to={url} onClick={handleDrawerClose}>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    );
  };

  const { userMenus, gameMenus } = getMenus();
  return (
    <Drawer
      // variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      anchor="left"
      open={open}
    >
      <ToolbarIcon>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </ToolbarIcon>
      <Divider />

    {userMenus.length !== 0 &&  <List>
        <ListSubheader inset>User</ListSubheader>
        {userMenus.map(renderItem)}
      </List>}
      <Divider />
      <List>
        <ListSubheader inset>Gaming</ListSubheader>
        {gameMenus.map(renderItem)}
      </List>
    </Drawer>
  );
}

export default AppDrawer;
