import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "./Loader";

export default function AlertDialog({
  onClose,
  onConfirm,

  loading,
  open,
  title,
  desc,
  confirmText = "Confirm",
  cancelText = "Cancel",
}) {
  function renderContent() {
    return (
      <>
        <DialogTitle>{title}</DialogTitle>
        {desc && (
          <DialogContent>
            <DialogContentText>{desc}</DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {cancelText}
          </Button>
          <Button onClick={() => onConfirm()} color="primary">
            {confirmText}
          </Button>
        </DialogActions>
      </>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      {loading ? <Loader /> : renderContent()}
    </Dialog>
  );
}
