import LibAutocomplete from "@material-ui/lab/Autocomplete";
import { useController, useFormContext } from "react-hook-form";
import styled from "styled-components";
// import TextField from "./TextField";
import TextField from "@material-ui/core/TextField";

const AppAutocomplete = styled(LibAutocomplete)({
  // marginBottom: 16,
});

const Autocomplete = ({
  label,
  name,
  rules,
  defaultValue = "",
  disabled,
  items = [],

  onBlur,
  margin,

  ...rest
}) => {
  const { control } = useFormContext();
  const {
    field: { ref: inputRef, onChange, value },
    fieldState: { error: { message } = {}, invalid: error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const inputProps = {
    name: "disabled",
    label,
    disabled,
    margin,
    helperText: message,
    error,

    onBlur,

    variant: "outlined",
    fullWidth: true,
  };

  const props = {
    ...rest,
    value,
    onChange: (e, newValue) => onChange(newValue),
    options: items,
    getOptionLabel: (text) => text || "",
    renderInput: (params) => <TextField {...params} {...inputProps} />,
  };

  return <AppAutocomplete {...props} />;
};

export default Autocomplete;
