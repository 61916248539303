import moment from "moment";
import { useState } from "react";
import { getDaysBetweenDates } from "../../helpers/DateTime";
import {
  getReportParams,
  sumGroupbyReportTxns,
  sumUplineAndEarning,
} from "../../helpers/Report";
import useDateReport from "./useDateReport";

function useDateReports() {
  const { refresh: dateRefresh } = useDateReport();

  const [response, setResponse] = useState();
  const [loading, setLoading] = useState();

  async function refresh({ startDate, endDate }) {
    setLoading(true);
    const allDates = getDaysBetweenDates(moment(startDate), moment(endDate));
    const allapis = allDates.map(async (date) => {
      try {
        const api = await dateRefresh({
          startDate: moment(date).startOf("day"),
          endDate: moment(date).endOf("day"),
        });

        return api;
      } catch (ex) {}
    });

    const allReportsApi = await Promise.all(allapis);

    const cleanReports = allReportsApi.filter((s) => !!s);

    const allReports = [].concat(...cleanReports.map((s) => s.reports));
    const gropupBy = sumGroupbyReportTxns(allReports.flatMap((s) => s.txns));
    const matchs = cleanReports.flatMap((s) => s.matchs);
    const uplineReport = sumUplineAndEarning(allReports);

    const report = getReportParams([], "", gropupBy);

    const result = {
      ...report,
      ...uplineReport,
      matchs,
    };

    setResponse(result);
    setLoading(false);

    return result;
  }

  return { response, loading, refresh };
}

export default useDateReports;
