import { isEmpty } from "./String";

export function mergeDeep(target, source) {
  if (!isObject(target) || !isObject(source)) {
    return source;
  }

  Object.keys(source).forEach((key) => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = targetValue.concat(sourceValue);
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = mergeDeep(Object.assign({}, targetValue), sourceValue);
    } else {
      target[key] = sourceValue;
    }
  });

  return target;
}

export function removeEmpty(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => !!v));
}

export const isObject = (obj) => obj && Object.is(obj);

export const convertObjectList = (lookup = [], prefix = "") =>
  lookup.reduce((prev, { value, text }) => {
    const key = prefix ? `${prefix}-${value}` : value;
    return { ...prev, [key]: text };
  }, {});

export const isEmptyObject = (obj) => !obj || Object.keys(obj).length === 0;

export const clean = (obj) =>
  Object.entries(obj)
    .filter(([_, v]) => !isEmpty(v))
    .reduce(
      (acc, [k, v]) => ({ ...acc, [k]: v === Object(v) ? clean(v) : v }),
      {}
    );
