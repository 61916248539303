import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Button from "@material-ui/core/Button";
import AppDivider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router";
import styled from "styled-components";
import Confirmation from "../components/Confirmation";
import FilterChips from "../components/FilterChips";
import Loader from "../components/Loader";
import TextField from "../components/TextField";
import { ROLE } from "../constants/Role";
import { useAppContext } from "../context/AppContext";
import { useAuthContext } from "../context/AuthContext";
import { uuid } from "../helpers/Common";
import { formatStandard } from "../helpers/DateTime";
import { MIN_RULE, MAX_RULE } from "../helpers/Form";
import useActiveMatchs from "../hooks/api/useActiveMatchs";
import useMatch from "../hooks/api/useMatch";
import useTxn from "../hooks/api/useTxn";
import useUser from "../hooks/api/useUser";
import useMount from "../hooks/useMount";
import Receipt from "./Receipt";

const CUTOFF_SEC = 60;

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(15),
  fontWeight: theme.typography.fontWeightBold,
  flexShrink: 0,
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(15),
  color: theme.palette.text.secondary,
  flex: 1,
  textAlign: "right",
}));

const DividerContent = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  // marginLeft: theme.spacing(2),
  // marginRight: theme.spacing(2),
}));

const Divider = styled(AppDivider)(({ theme, remark }) => ({
  flex: 1,

  marginTop: remark && theme.spacing(2),
  marginBottom: remark && theme.spacing(2),
}));

const DividerText = styled(Typography).attrs(({ theme }) => ({
  variant: "overline",
  display: "block",
  align: "center",
  color: "primary",
}))(({ theme }) => ({
  margin: theme.spacing(2),
}));

const RemarkInput = styled(TextField).attrs({
  label: "Remark",
  margin: "dense",
  multiline: true,
  rows: 3,
  rowsMax: 3,
})({});

function Betting() {
  const { setPageTitle, setMessage } = useAppContext();
  const { user } = useAuthContext();
  const { id = "" } = useParams();

  const [showReceipt, setShowReceipt] = useState();
  const [bet, setBet] = useState();
  const [selected, setSelected] = useState();
  const [selectedFilter, setSelectedFilter] = useState([]);

  const {
    response: receipt,
    create: createTxn,
    loading: txnLoading,
  } = useTxn();

  const {
    refresh,
    loading: creditLoading,
    response: me,
  } = useUser({
    auto: true,
    id: "me",
  });

  const { role } = user ?? {};
  const isMasterRole = role === ROLE.master;

  const { masterId: mId, id: username } = me ?? {};
  const masterId = isMasterRole ? username : mId;

  const {
    response = [],
    loading,
    refresh: refreshMatchs,
  } = useActiveMatchs({ id: masterId, activeOnly: true, liteOnly: true });

  const {
    response: match,
    loading: matchLoading,
    refreshById: refreshMatch,
  } = useMatch({
    auto: false,
  });

  const anyLoading = txnLoading || creditLoading || matchLoading;

  const inputProps = {
    rules: {
      ...MIN_RULE(1),
    },
  };

  const form = useForm();
  const { handleSubmit, watch, reset } = form;

  const betId = useRef(uuid());

  useMount(() => {
    setPageTitle("Betting");
  });

  useEffect(() => {
    if (!masterId) {
      return;
    }

    refreshMatchs();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterId]);

  useEffect(() => {
    if (!response || !id) {
      return;
    }

    const found = response.find((s) => s.id === id);
    setSelected(found);
  }, []);

  useEffect(() => {
    if (!response || !id) {
      return;
    }

    const found = response.find((s) => s.id === id);
    setSelected(found);
  }, [id, response]);

  useEffect(() => {
    refreshMatch(selected?.id);
  }, [selected?.id]);

  async function onSubmit(data, item) {
    const { homeOdds, awayOdds, drawOdds, playAt } = item;
    const allOdds = [...homeOdds, ...awayOdds, ...drawOdds];

    const cutOff = moment(playAt).subtract(CUTOFF_SEC, "seconds");
    const expired = moment(cutOff).isSameOrBefore(moment());

    if (expired) {
      setMessage({ message: "Bet Closed" });
      return;
    }

    if (anyLoading) {
      return;
    }

    const odds = allOdds.map((odd, index) => ({
      ...data.odds[index],
      ...odd,
    }));

    const formatted = {
      products: odds
        .filter((s) => !!s.betAmount)
        .map((s) => ({
          ...s,
          betAmount: +s.betAmount,
        })),
      remark: data.remark,
    };

    const totalAmount = formatted.products.reduce(
      (prev, odd) => prev + +odd.betAmount,
      0
    );

    if (totalAmount === 0) {
      setMessage({ message: "Cannot submit $0 bet" });
      return;
    }

    const { credit } = await refresh();
    if (totalAmount > credit) {
      setMessage({ message: "Credit not enough to proceed bet" });
      return;
    }

    setBet(formatted);
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setSelected(isExpanded ? panel : undefined);
  };

  const onFilterItems = (filter) => {
    setSelectedFilter(filter);
  };

  const getFiltered = () => {
    if (selectedFilter.length === 0) {
      return response;
    }

    return response.filter((item) => {
      const { playAt } = item;

      const validToday = moment(playAt).isBetween(
        moment().startOf("day"),
        moment().endOf("day")
      );
      const validTomorrow = moment(playAt).isBetween(
        moment().add(1, "day").startOf("day"),
        moment().add(1, "day").endOf("day")
      );

      if (selectedFilter.includes("Today Match") && validToday) {
        return true;
      }

      if (selectedFilter.includes("Future Match") && validTomorrow) {
        return true;
      }

      return false;
    });
  };

  function renderDivider(title) {
    return (
      <DividerContent>
        <Divider />
        <DividerText>{title}</DividerText>
        <Divider />
      </DividerContent>
    );
  }

  function renderOdd({ score, amount }, index, limits, formName) {
    const name = `${formName}.${index}.betAmount`;
    const { limit, currentLimit } = limits.find((s) => s.score === score) ?? {};
    const notAvailable = currentLimit === 0;
    const input2Props = {
      ...inputProps,
      disabled: notAvailable,
      rules: {
        ...inputProps.rules,
        ...(limit ? MAX_RULE(currentLimit) : {}),
      },
    };
    return (
      <Grid key={name} item xs={12} md={6} lg={4}>
        <TextField
          {...input2Props}
          name={name}
          label={score}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {notAvailable ? "N/A" : `x ${amount} $`}
              </InputAdornment>
            ),
            endAdornment:
              !notAvailable && limit ? (
                <InputAdornment position="end">/ {currentLimit}</InputAdornment>
              ) : null,
          }}
          margin="dense"
        />
      </Grid>
    );
  }

  function renderForm() {
    if (matchLoading) {
      return (
        <Grid item xs={12}>
          <Loader />
        </Grid>
      );
    }

    const {
      id,
      home,
      away,
      homeOdds = [],
      awayOdds = [],
      drawOdds = [],

      homeOddsLimit = [],
      awayOddsLimit = [],
      drawOddsLimit = [],
    } = match || {};
    const formValues = watch();

    const total = formValues[id]?.odds?.reduce(
      (prev, odd) => prev + +odd.betAmount,
      0
    );

    return (
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(() => onSubmit(formValues[id], match))}>
          {renderDivider(home)}
          <Grid container spacing={2}>
            {homeOdds.map((item, index) =>
              renderOdd(item, index, homeOddsLimit, `${id}.odds`)
            )}
          </Grid>
          {renderDivider(away)}
          <Grid container spacing={2}>
            {awayOdds.map((item, index) =>
              renderOdd(
                item,
                homeOdds.length + index,
                awayOddsLimit,
                `${id}.odds`
              )
            )}
          </Grid>
          {renderDivider("Draw")}
          <Grid container spacing={2}>
            {drawOdds.map((item, index) =>
              renderOdd(
                item,
                homeOdds.length + awayOdds.length + index,
                drawOddsLimit,
                `${id}.odds`
              )
            )}
          </Grid>
          <Divider remark />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <RemarkInput name={`${id}.remark`} />
            </Grid>
          </Grid>
          <Divider remark />
          <Button type="submit" color="primary">
            {creditLoading
              ? "Loading ..."
              : `Submit ${total ? `(Total: $${total})` : ""}`}
          </Button>
        </form>
      </FormProvider>
    );
  }

  function renderItems(item) {
    const { id, name, playAt } = item;
    const expanded = selected?.id === id;

    return (
      <Accordion expanded={expanded} onChange={handleChange(item)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Heading>{name}</Heading>
          <SubHeading>Start At: {formatStandard(playAt)}</SubHeading>
        </AccordionSummary>
        <AccordionDetails>{expanded && renderForm()}</AccordionDetails>
      </Accordion>
    );
  }

  function renderContent() {
    const filtered = getFiltered();
    return (
      <>
        <Grid item xs={12}>
          <FilterChips onChange={onFilterItems} />
        </Grid>
        <Grid item xs={12}>
          {filtered.map(renderItems)}
        </Grid>
      </>
    );
  }

  const total = bet?.products.reduce((prev, odd) => prev + +odd.betAmount, 0);

  return (
    <>
      <Grid container spacing={2}>
        {loading ? (
          <Grid item xs={12}>
            <Loader paper />
          </Grid>
        ) : (
          renderContent()
        )}
      </Grid>

      <Receipt
        item={receipt}
        onClose={() => setShowReceipt()}
        open={showReceipt}
      />
      <Confirmation
        title={`Submit Bet - ${selected?.name}`}
        desc={`Total Amount: $${total}. Are you sure want to submit bet now?`}
        open={!!bet}
        loading={anyLoading}
        onClose={() => setBet()}
        onConfirm={async () => {
          if (anyLoading) {
            return;
          }

          try {
            const { id: matchId, playAt: matchAt, name: matchName } = selected;
            await createTxn({
              matchId,
              matchAt,
              matchName,
              ...bet,
              id: betId.current,
            });
            await refreshMatch(matchId);

            setBet();

            setShowReceipt(true);
            reset({ [matchId]: {} });

            // new uuid
            betId.current = uuid();
          } catch (ex) {
            setBet();
            setMessage(ex);
          }
        }}
      />
    </>
  );
}

export default Betting;
