import { API_URL } from "../../constants/EnvConfig";
import useAuthFetch from "../useAuthFetch";

function useBetReport({ id }) {
  const url = `${API_URL}/report/bet/${id}`;
  const { response, loading, get } = useAuthFetch({ url });

  function refresh() {
    return get({ url });
  }

  return { response, loading, refresh };
}

export default useBetReport;
