import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import React, { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import styled from "styled-components";
import Loader from "../components/Loader";
import Select from "../components/Select";
import PasswordPolicy from "../components/PasswordPolicy";
import TextField from "../components/TextField";
import { useAppContext } from "../context/AppContext";
import {
  MAX_RULE,
  MIN_RULE,
  PASSWORD_POLICY,
  REQUIRED_RULE,
} from "../helpers/Form";
import useUser from "../hooks/api/useUser";
import useForm from "../hooks/useForm";
import useMount from "../hooks/useMount";
import { ROLE, ALLOW_ROLES } from "../constants/Role";
import { useAuthContext } from "../context/AuthContext";
import { useParams } from "react-router";
import { InputAdornment } from "@material-ui/core";
import useAgent from "../hooks/api/useAgent";
import Switch from "../components/Switch";

const UsernameInput = styled(TextField).attrs({
  name: "username",
  label: "Username",
  rules: REQUIRED_RULE,
})({});

const FullnameInput = styled(TextField).attrs({
  name: "name",
  label: "Full Name",
  rules: REQUIRED_RULE,
})({});

const RoleInput = styled(Select).attrs({
  name: "role",
  label: "Role",
  rules: REQUIRED_RULE,
})({});

const PasswordInput = styled(TextField).attrs({
  name: "password",
  label: "Password",
  type: "password",
  rules: { ...REQUIRED_RULE, pattern: PASSWORD_POLICY.pattern },
})({});

const CommissionSwitch = styled(Switch).attrs({
  name: "showCommission",
  label: "Show Commmission",
})({});

const FightSwitch = styled(Switch).attrs({
  name: "showFight",
  label: "Show Fight",
})({});

const CreditInput = styled(TextField).attrs({
  name: "credit",
  label: "Credit",
  type: "tel",
})({});

const CommissionInput = styled(TextField).attrs({
  name: "commission",
  label: "Commission %",
  type: "tel",
})({});

const FightInput = styled(TextField).attrs({
  name: "fight",
  label: "Fight %",
  type: "tel",
})({});

function AddUser() {
  const { setPageTitle, setMessage } = useAppContext();
  const { user } = useAuthContext();
  const { loading, create } = useUser();

  const { id } = useParams();

  const { role: userRole } = user ?? {};
  const roleItems = Object.values(ALLOW_ROLES[userRole]).map((value) => ({
    value,
    text: value,
  }));

  const isAdminRole = userRole === ROLE.admin;
  const isMasterRole = userRole === ROLE.master;

  const {
    response: agent,
    loading: agentLoading,
    refresh,
  } = useAgent({
    id,
    auto: !!id,
  });

  const form = useForm({
    defaultValues: {
      role: roleItems[0].value,
      showCommission: true,
      showFight: true,
    },
  });
  const { handleSubmit, watch, reset } = form;
  const {
    password,
    role,
    showCommission: showComm,
    showFight: showF,
  } = watch();

  const isAgent = role === ROLE.agent;
  const isAdmin = role === ROLE.admin;
  const isMaster = role === ROLE.master;
  const isReport = role === ROLE.report;

  useMount(() => {
    setPageTitle(`User Add`);
  });

  function getInputProps(limit, allowZero) {
    if (limit === undefined || (limit === 0 && allowZero)) {
      return {
        rules: {
          ...REQUIRED_RULE,
          ...MIN_RULE(0),
        },
      };
    }

    return {
      rules: {
        ...REQUIRED_RULE,
        ...MIN_RULE(0),
        ...MAX_RULE(limit),
      },
      InputProps: {
        endAdornment: <InputAdornment position="end">/{limit}</InputAdornment>,
      },
    };
  }

  async function onSubmit({ showCommission, showFight, ...data }) {
    try {
      const body = isReport
        ? { ...data, commission: 0, fight: 0, credit: 0 }
        : isMaster
        ? { ...data, commission: 100, fight: 0 }
        : data;
      await create({
        ...body,
        fight: !showFight ? 0 : body.fight || 0,
        commission: !showCommission ? 0 : body.commission || 0,
        showCommission: !!showCommission,
        showFight: !!showFight,
      });
      setMessage("User added");
      reset();
      await refresh();
    } catch (ex) {
      const { message = "Username has been registered" } = ex;
      setMessage({ message });
    }
  }

  function renderCredit() {
    if (isAdmin) {
      return null;
    }

    // todo leter
    // const { agent: upper } = agent ?? {};
    // const upLine = isAdminRole ? agent : agent?.agent;
    // const upLine = agent;
    const {
      showCommission = true,
      showFight = true,
      commission,
      credit,
      fight,
    } = agent ?? {};

    return (
      <>
        <Grid item xs={12}>
          {!isReport && (
            <CreditInput
              {...getInputProps(isAdminRole ? 0 : credit, isAdminRole)}
            />
          )}
        </Grid>
        {isAgent && (
          <>
            {showCommission && (
              <Grid item xs={12}>
                <CommissionSwitch />
                {showComm && (
                  <CommissionInput
                    {...getInputProps(
                      isAdminRole || isMasterRole ? 0 : commission,
                      isAdminRole || isMasterRole
                    )}
                  />
                )}
              </Grid>
            )}
            {showFight && (
              <Grid item xs={12}>
                <FightSwitch />
                {showF && (
                  <FightInput
                    {...getInputProps(
                      isAdminRole || isMasterRole ? 0 : fight,
                      isAdminRole || isMasterRole
                    )}
                  />
                )}
              </Grid>
            )}
          </>
        )}
      </>
    );
  }

  function renderDetail() {
    const policyProps = {
      value: password,
      isSubmitted: false,
    };

    return (
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <CardContent>
              <FormProvider {...form}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <UsernameInput />
                  </Grid>
                  <Grid item xs={12}>
                    <FullnameInput />
                  </Grid>
                  {roleItems.length !== 1 && (
                    <Grid item xs={12}>
                      <RoleInput items={roleItems} />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <PasswordInput />
                    <PasswordPolicy {...policyProps} />
                  </Grid>
                  {renderCredit()}
                </Grid>
              </FormProvider>
            </CardContent>
            <CardActions>
              <Button type="submit" color="primary">
                Create User
              </Button>
            </CardActions>
          </Card>
        </form>
      </FormProvider>
    );
  }

  return (
    <Grid container spacing={2} justify="center">
      <Grid item lg={6}>
        {loading || agentLoading ? <Loader /> : renderDetail()}
      </Grid>
    </Grid>
  );
}

export default AddUser;
