import { useCallback, useEffect, useState } from "react";
import { appHttp } from "../helpers/Http";
import { isObject } from "../helpers/Object";
import useCache from "./useCache";

const useFetch = (
  { url, default: defaultRes, cache = false, ...options },
  format
) => {
  const [response, setResponse] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const { get: getCache, store: storeCache, wait: waitCache } = useCache(cache);

  const makeFetch = useCallback(
    async (ops) => {
      async function getOrCache() {
        const { url: updated, ...restOps } = ops ?? {};
        const apiUrl = updated ?? url;
        if (!apiUrl) {
          // setResponse();
          return response;
        }

        const cached = await getCache(apiUrl);
        if (cached) {
          return cached;
        }

        waitCache(apiUrl);
        // TODO: remove once publish
        console.log("appHttp", {
          apiUrl,
          method: restOps.method,
        });
        const result = await appHttp(apiUrl, {
          ...options,
          ...restOps,
        });
        const res = format ? format(result) : result;
        storeCache(apiUrl, res);
        return res;
      }

      setLoading(true);
      try {
        const res = await getOrCache();
        setResponse((r) => (isObject(r) ? { ...r, ...res } : res));
        setTimeout(() => {
          setLoading(false);
        }, 300);
        return res;
      } catch (ex) {
        setError(ex);
        setLoading(false);
        setResponse(defaultRes);

        // console.log({ex, url});
        if (defaultRes) {
          return defaultRes;
        }

        throw ex;
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [url]
  );

  useEffect(() => {
    makeFetch();

    return () => {
      setResponse(undefined);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const get = useCallback((ops) => makeFetch(ops), [makeFetch]);
  const post = useCallback(
    (ops) => makeFetch({ ...ops, method: "POST" }),
    [makeFetch]
  );
  const put = useCallback(
    (ops) => makeFetch({ ...ops, method: "PUT" }),
    [makeFetch]
  );
  const del = useCallback(
    (ops) => makeFetch({ ...ops, method: "DELETE" }),
    [makeFetch]
  );

  return {
    response,
    error,
    loading,

    get,
    post,
    put,
    del,
  };
};

export default useFetch;
